import dashboard_analytics from "../pages/app/admin/dashboard_analytics";
import Nocs from "../pages/app/certificatetypes/Nocs";

export const ServiceUrl = {
  V2_LOGIN: {
    CHECK_USERNAME: "v2/login/check-username",
    CAPTCHA: "v1/captcha",
    VERIFY_CAPTCHA: "v2/login/verify-captcha",
    UPDATE_PASSWORD: "v2/login/update-pass",
  },
  DASHBOARD: {
    FIRST_LINE_COUNT: "v1/admin/dashboard/first-line-counts",
    STUDENTS_BIRTHDAY_LIST: "v1/admin/dashboard/students-birthday-list",
    STAFFS_BIRTHDAY_LIST: "v1/admin/dashboard/staffs-birthday-list",
    STAFFS_LEAVE_LIST: "v1/admin/dashboard/staffs-leave-list",
    STAFFS_LEAVE_DETAILS: "v1/admin/dashboard/staffs-leave-detail",
    STAFFS_BIRTHDAY_NEW: "v1/admin/dashboard/staffs-birthday-new",

    STUDENTS_COUNT: "v1/admin/dashboard/students-count",
    TODAY_DAY_ORDER: "v1/admin/dashboard/today-day-order",
    TODAY_ATTENDANCE_COUNT: "v1/admin/dashboard/today-attendance-count",
    TODAYS_ABSENTEES_COUNT: "v1/admin/dashboard/todays-absentees-count",
    DATEWISE_ABSENTEES_COUNT: "v1/admin/dashboard/date-wise-absentees-count",

    TODAYS_UNATTENDED_CLASS: "v1/admin/dashboard/todays-unattended-class",
    TODAY_TIME_TABLE: "v1/admin/dashboard/time-table-today",

    TODAY_COLLECTION_SUMMARY: "v1/admin/dashboard/today-collection-summary",
    ONLINE_COLLECTION_SUMMARY: "v1/admin/dashboard/online-collection-summary",
  },
  DASHBOARD_ANALYTICS: {
    LIST_ANALYTICS_DATA: "v1/admin/dashboarda/analytics-data",
  },
  OPEN_ACCESS: {
    QUALIFICATION_CATEGORIES: "v1/set/qualification-categories",
    COURSE_DEPT_BATCH: "v1/set/course-dept-batch",
    STUDENT_ONLINE_ORDERS_LIST: "v1/oa/stupay/online-list",
  },
  ACADEMIC: {
    LIST_ACADEMIC_YEARS: "v1/admin/academic_years/list-academic-years",
    LIST_COURSES: "v1/admin/courses/list-courses",
    LIST_COURSES_V2: "v1/admin/courses/list-courses", // New Course List based on v2 Department
    REMOVE_COURSE_FROM_DEPT: "v1/admin/courses/remove-course-from-dept", // New Course List based on v2 Department

    SAVE_COURSES: "v1/admin/courses/save-course",
    UPDATE_COURSES: "v1/admin/courses/update-course",
    REMOVE_COURSES: "v1/admin/courses/remove-course",

    SAVE_COURSE_SUBJECT: "v1/admin/lessonPlanning/save-course-subject",
    UPDATE_COURSE_SUBJECT: "v1/admin/lessonPlanning/update-course-subject",
    LIST_COURSE_SUBJECT: "v1/admin/lessonPlanning/list-course-subject",
    COUNT_COURSE_SUBJECT: "v1/admin/lessonPlanning/count-course-subject",

    SAVE_UNITS: "v1/admin/lessonPlanning/save-unit",
    UPDATE_UNITS: "v1/admin/lessonPlanning/update-units",
    UPDATE_DESCRIPTION: "v1/admin/lessonPlanning/update-description",
    LIST_UNITS: "v1/admin/lessonPlanning/list-unit",
    DELETE_UNITS: "v1/admin/lessonPlanning/delete-unit",

    SAVE_UNIT_TOPICS: "v1/admin/lessonPlanning/save-unit-topics",
    UPDATE_UNIT_TOPICS: "v1/admin/lessonPlanning/update-unit-topics",
    DELETE_UNIT_TOPICS: "v1/admin/lessonPlanning/delete-unit-topics",
    LIST_UNIT_TOPICS: "v1/admin/lessonPlanning/list-unit-topics",

    LIST_BATCH: "v1/admin/lessonPlanning/list-batch",

    COUNT_LESSON_PLAN_REPORT:
      "v1/admin/lessonPlanning/count-lesson-plan-report",
    LIST_LESSON_PLAN_REPORT: "v1/admin/lessonPlanning/lesson-plan-report",
    UPDATE_COURSE_FIELD: "v1/admin/courses/update-field",

    SAVE_SUBJECT_SETUP: "v1/admin/lessonPlanning/save-subject-setup",
    LIST_TUTORIALS: "v1/admin/lessonPlanning/list-tutorials",
  },
  LOGIN_V2: {
    CHECK: "/v2/admin/check",
    CHECK_USERNAME: "/v1/admin/login",
  },
  ADMISSION: {
    SETTINGS: "v1/settings",
    SETTINGS_MENU: "v1/admin/settings/menu-list",
    LIST_VARIABLE_SETTINGS: "v1/admin/settings/list-settings",
    UPDATE_VARIABLE_SETTINGS: "v1/admin/settings/update-settings",
    ADD_VARIABLE_SETTINGS: "v1/admin/settings/add-settings",

    SAVE_IMAGE_SETTINGS: "v1/admin/settings/add-image-settings",
    LIST_IMAGE_SETTINGS: "v1/admin/settings/list-image-settings",
    ADD_RATIO: "v1/admin/settings/add-ratio",
    DELETE_RATIO: "v1/admin/settings/delete-ratio",

    LIST_SMS_TEMPLATES: "v1/admin/smstemplate/list-templates",
    UPDATE_SMS_TEMPLATES: "v1/admin/smstemplate/update-template",
    ADD_SMS_TEMPLATES: "v1/admin/smstemplate/add-template",
    DELETE_SMS_TEMPLATES: "v1/admin/smstemplate/delete-template",

    LIST_MAIL_TEMPLATES: "v1/admin/mailtemplate/list-templates",
    UPDATE_MAIL_TEMPLATES: "v1/admin/mailtemplate/update-template",
    ADD_MAIL_TEMPLATES: "v1/admin/mailtemplate/add-template",
    DELETE_MAIL_TEMPLATES: "v1/admin/mailtemplate/delete-template",

    LIST_PAGES: "v1/admin/webpage/list-pages",
    UPDATE_PAGE: "v1/admin/webpage/update-page",
    VIEW_PAGE: "v1/admin/webpage/view-page",
    DELETE_PAGE: "v1/admin/webpage/delete-page",
    ADD_PAGE: "v1/admin/webpage/add-page",

    USER_PROFILE: "v1/admin/users/my-profile",
    USER_CHANGE_PASSWORD: "v1/admin/users/change-password",

    NEW_USER_LOGIN: "v1/admin/users/save-user",
    LIST_USERS_LOGIN: "v1/admin/users/list-users",
    USER_STATUS_CHANGE: "v1/admin/users/status-change",
    REMOVE_USER_LOGIN: "v1/admin/users/remove-user",
    UPDATE_USER_LOGIN: "v1/admin/users/update-user",
    PASS_USER_LOGIN: "v1/admin/users/user-password",
    DASHBOARDA_COUNT: "v1/admin/",
  },
  STUDENTS: {
    SEARCH_BY_REGISTER_NO: "v1/admin/student_search/search-student-by-no",
    SEARCH_BY_REGISTER_NO_TC: "v1/admin/student_search/search-student-by-no-tc",
    SEARCH_BY_OVERALL_REPORT: "v1/admin/student_search/search-student-overall",
    OVERALL_TEMPLATE_REPROT: "v1/admin/student_search/save-student-template",
    LIST_TEMPLATE: "v1/admin/student_search/list-template",
    ID_CARD_DOWNLOAD: "v1/admin/id_card_download/id-card-download",
    ZIP: "v1/admin/id_card_download/zip",
    SEARCH_BY_NAME: "v1/admin/student_search/search-by-name",
    INCOMPLETE_PROFILES: "v1/admin/student_search/incomplete",
    QUICK_EDIT_LIST: "v1/admin/student_search/quick-edit-list",
    QUICK_EDIT_UPDATE_STUDENT:
      "v1/admin/student/quickedittype/update-student-quick-edit",
    LIST_QUICK_EDIT_CONFIG:
      "v1/admin/student/quickedittype/list-quick-edit-config",
    STUDENT_ADMISSION_REGISTER: "v1/admin/student/admission-register",
    UG_STUDENT_ADMISSION_REGISTER_COUNT:
      "v1/admin/student/ug-admission-register-count",
    PG_STUDENT_ADMISSION_REGISTER_COUNT:
      "v1/admin/student/pg-admission-register-count",
    STUDENT_ADMISSION_NO: "v1/admin/student/admission-no",
    SAVE_STUDENT_FORM_TWO: "v1/admin/student/add-student-form-two",
    SAVE_BUS_ROUTE: "v1/admin/student/save-vehicle",
    TRANSPORT_DETAILS: "v1/admin/student_search/transport-details",
    PROMOTION_DETAILS: "v1/admin/student_search/promotion-details",
    SAVE_PROMOTION: "v1/admin/student/save-promotion",
    REMOVE_PROMOTION: "v1/admin/student/remove-promotion",
    UPDATE_BULK_FIELD: "v1/admin/student/update-bulk-field",
    SAVE_RE_ADMISSION: "v1/admin/student/save-readmission",

    SAVE_STUDENT: "v1/admin/student/save-student",
    UPDATE_STUDENT: "v1/admin/student/update-student",
    UPDATE_HOSTEL_DATES: "v1/admin/student/update-hostel-dates",
    DELETE_STUDENT: "v1/admin/student/delete",

    STUDENT_STRENGTH: "v1/admin/student_report/current-strength",
    STRENGTH_REPORT: "v1/admin/student_report/strength-report",
    STUDENT_COMMNUITY_REPORT:
      "v1/admin/student_community_report/student-commnuity-report",
    STUDENT_RELIGION_REPORT:
      "v1/admin/student_community_report/student-religion-report",

    ADMISSION_ALLOCATION_SAVE: "v1/admin/admission-allocation/save",
    ADMISSION_ALLOCATION_LIST: "v1/admin/admission-allocation/list",
    ADMISSION_ALLOCATION_DELETE: "v1/admin/admission-allocation/delete",
    ADMISSION_ALLOCATION_UPDATE: "v1/admin/admission-allocation/update",

    STUDENTS_PROFILE_INCOMPLETE_COUNT:
      "v1/admin/student_report/incomplete-count",
    STUDENTS_PROFILE_INCOMPLETE_LIST:
      "v1/admin/student_report/incomplete-students",
    INCOMPLETE_REPORT: "v1/admin/student_report/incomplete-report",

    IMPORT_STUDENT: "v1/admin/importstudent/save",
    BULK_PHOTO_UPLOAD: "v1/admin/student/bulk-photo-upload",
    BULK_UPDATE: "v1/admin/student/bulk-update",
    UPDATE_TC_DATES: "v1/admin/student/tc-date-update",
    SAVE_STUDENT_TC: "v1/admin/student/save-tc",
    GET_TC_NO: "v1/admin/student/tc-no",
    SAVE_STUDENT_BULK_TC: "v1/admin/student/save-bulk-tc",
    LIST_STUDENT_TC: "v1/admin/student/list-tc",
    LIST_PENDING_DETAILS: "v1/admin/student/list-tc-pending-details",
    COUNT_PENDING_DETAILS: "v1/admin/student/count-tc-pending-details",
    SAVE_APPLIED_DATE: "v1/admin/student/save-applied-date",
    UPDATE_TRANSPORT_ID: "v1/admin/student/update-transport-id",

    SAVE_BONAFIED_CERTIFICATE: "v1/admin/student/bonaifed/save",
    LIST_BONAFIED_CERTIFICATES: "v1/admin/student/bonaifed/list-all",
    LIST_FEE_TEMPLATES: "v1/admin/student/bonaifed/list-fees",
    DELETE_BONAFIED_CERTIFICATE: "v1/admin/student/bonaifed/delete",
    SAVE_VERIFICATION_CERTIFICATE: "v1/admin/student/verification/save",
    LIST_VERIFICATION_CERTIFICATES: "v1/admin/student/verification/list-all",

    /* DELETE_VERIFICATION_CERTIFICATE: "v1/admin/student/bonaifed/delete", */
    SAVE_CONDUCT_CERTIFICATE: "v1/admin/student/conduct/save",
    LIST_CONDUCT_CERTIFICATES: "v1/admin/student/conduct/list-all",
    DELETE_CONDUCT_CERTIFICATE: "v1/admin/student/conduct/delete",
    SAVE_MEDIUM_CERTIFICATE: "v1/admin/student/medium/save",
    LIST_MEDIUM_CERTIFICATES: "v1/admin/student/medium/list-all",
    DELETE_MEDIUM_CERTIFICATE: "v1/admin/student/medium/delete",
    LIST_VERIFY_CERTIFICATE: "v1/admin/student/verification/list",
    SAVE_VERIFY_CERTIFICATE: "v1/admin/student/verification/save",
    SAVE_ATTENDANCE_CERTIFICATE: "v1/admin/student/attendancecertificate/save",
    LIST_ATTENDANCE_CERTIFICATES:
      "v1/admin/student/attendancecertificate/list-all",
    LIST_GENUINE_CERTIFICATE: "v1/admin/student/genuine_cert/list-all",
    SAVE_GENUINE_CERTIFICATE: "v1/admin/student/genuine_cert/save",
    MY_ATTENDANCE_DATA: "v1/admin/student/attendance/my-attendance-data",
    LIST_UNMARKED_ATTENDANCE: "v1/admin/student/attendance/unmarked-list",

    SAVE_COMPLETION_CERTIFICATE: "v1/admin/student/completion/save",
    LIST_COMPLETION_CERTIFICATES: "v1/admin/student/completion/list-all",
    DELETE_COMPLETION_CERTIFICATE: "v1/admin/student/completion/delete",

    LIST_BATCHES_COURSES: "v1/admin/stu_daily_report/list-batches-and-courses",
    LIST_ATTENDANCE: "v1/admin/stu_daily_report/list-attendance",
    LIST_ATTENDANCE_RECORD: "v1/admin/stu_daily_report/list-attendance-record",

    SAVE_ID_CARD: "v1/admin/student/idcard/save",
    LIST_ID_CARD: "v1/admin/student/idcard/list-all",
    DELETE_ID_CARD: "v1/admin/student/idcard/delete",

    LEARNING_OUTCOME_SAVE: "v1/admin/lerning_outcome/save",
    LEARNING_OUTCOME_UPDATE: "v1/admin/lerning_outcome/update",
    LEARNING_OUTCOME_UPDATE_ATTACHEMENT:
      "v1/admin/lerning_outcome/update-attachement",
    LEARNING_OUTCOME_LIST: "v1/admin/lerning_outcome/list",
    LEARNING_OUTCOME_DELECT: "v1/admin/lerning_outcome/delect",
    LEARNING_OUTCOME_LIST_STUDENT: "v1/admin/lerning_outcome/list-student",
    LEARNING_OUTCOME_STAFF_SUBJECT:
      "v1/admin/lerning_outcome/list-staffbysubject",
    LEARNING_OUTCOME_STUDENT_SAVE: "v1/admin/lerning_outcome/student-save",
    LEARNING_OUTCOME_STUDENT_VIEW: "v1/admin/lerning_outcome/view-student",
    LEARNING_OUTCOME_LIST_COURSE: "v1/admin/lerning_outcome/listallcourse",
    LEARNING_OUTCOME_STUDENT_DELECT:
      "v1/admin/lerning_outcome/view-student-delect",

    LIST_TARGET_MARK: "v1/admin/target_marks/list",
    LIST_TARGET_MARK_STUDENT: "v1/admin/target_marks/list-student",

    DATE_WISE_ATTENDANCE: "v1/admin/student/attendance/get",
    UPDATE_DAY_ATTENDANCE: "v1/admin/student/attendance/update",
    SAVE_LONG_ABSENT_STUDENT: "v1/admin/student/attendance/save-long-absent",
    LONG_ABSENT_STUDENTS: "v1/admin/student/attendance/long-absentee",
    GET_MARKED_GROUP_BY_DAYHOUR: "v1/admin/student/attendance/get-marked",
    DELETE_LONG_ABSENT_STUDENT:
      "v1/admin/student/attendance/delete-long-absentee",
    DAy_WISE_ENTRY_RECORDS: "v1/admin/student/attendance/get-day-records",
    DELETE_DAY_ENTRY: "v1/admin/student/attendance/delete-day-records",
    MARK_BULK_OD: "v1/admin/student/attendance/mark-bulkod",
    DAY_WISE_ATTENDANCE_COUNT: "v1/admin/student/attendance/day-wise-count",
    SAVE_SINGLE_OD_ENTRY: "v1/admin/student/attendance/single-od-update",

    LIST_QUICK_EDIT_TYPE: "v1/admin/student/quickedittype/list",
    ADD_QUICK_EDIT_TYPE: "v1/admin/student/quickedittype/save",
    EDIT_QUICK_EDIT_TYPE: "v1/admin/student/quickedittype/update",
    REMOVE_QUICK_EDIT_TYPE: "v1/admin/student/quickedittype/delete",

    ATTENDANCE_COUNT_REPORT: "v1/admin/student/attendance/reports",
    ATTENDANCE_MONTHLY_REPORT: "v1/admin/student/attendance/monthly-report",
    STUDENT_SMS_LOGS: "v1/admin/student/attendance/sms-logs",
    TODAY_FULL_ABSENT_COUNT: "v1/admin/student/attendance/full-absent-count",
    COURSE_LACK_REPORT: "v1/admin/student/attendance/course-lack-report",
    OD_REGISTER_LOGS: "v1/admin/student/attendance/od-logs",
    SEND_SMS_ABSENTES_MANUALLY:
      "v1/admin/student/attendance/send-sms-to-absentees",

    ALLOCATED_CLASS_WISE_SUBJECTS: "v1/admin/student/subject/allocated-list",
    ALLOCATE_CLASS_WISE_SUBJECTS: "v1/admin/student/subject/allocate",
    STUDENTS_SUBJECT_LIST: "v1/admin/student/subject/students-allocated-list",
    DELETE_STUDENT_SUBJECT: "v1/admin/student/subject/delete-student-subject",
    BULK_DELETE_STUDENT_SUBJECT: "v1/admin/student/subject/bulk-delete",
    BULK_UPDATE_STUDENT_SUBJECT: "v1/admin/student/subject/bulk-update",
    FIND_COURSE_SUBJECT: "v1/admin/student/subject/find",
    UPDATE_STUDENT_ALLOCATED_SUBJECT: "v1/admin/student/subject/change-subject",
    SAVE_STUDENT_ALLOCATED_SUBJECT: "v1/admin/student/subject/add-subject",
    DELETE_ALLOCATED_SUBJECTS: "v1/admin/student/subject/delete-allocated",
    STUDENT_SUBJECT_MANUAL_IMPORT: "v1/admin/student/subject/manual-import",
    MERGE_STUDENT_POLLING_LOGS: "v1/admin/student/subject/merge-polling-logs",

    STUDENTS_SUBJECT_UNALLOCATED_LIST:
      "v1/admin/student/subject/students-unallocated-list",

    REMOVE_LEFT: "v1/admin/student/delete-left",
    UPDATE_LEFT: "v1/admin/student/update-left",

    SAVE_HOSTEL_ADMISSION: "v1/admin/hostel/admission/save",
    LIST_HOSTEL_STUDENTS: "v1/admin/hostel/admission/list",
    DELETE_HOSTEL_STUDENT: "v1/admin/hostel/admission/delete",
    UPDATE_HOSTEL_STUDENT: "v1/admin/hostel/admission/update",
    CHANGE_STUDENT_ROOM: "v1/admin/hostel/admission/change-room",
    SAVE_BULK_HOSTEL_ADMISSION: "v1/admin/hostel/admission/bulk-save",
    HOSTEL_ATTENDANCE_MONTHLY_REPORT:
      "v1/admin/hostel/admission/monthly-report",

    STUDENT_TRANSPORT_LIST: "v1/admin/student/trasnport/students-list",
    STUDENT_TRANSPORT_BULK_ADMISSION:
      "v1/admin/student/trasnport/bulk-admission",

    BULK_ADDON_ADMISSION: "v1/admin/student/bulk-addon-admission",

    NEW_STUDENT_ADMISSION: "v1/admin/student/admission",
    REQUEST: {
      LIST: "v1/admin/student/service_request/list-all",
      EDIT: "v1/admin/student/service_request/edit-request",
      LIST_STATUS: "v1/admin/student/service_request/list-request-status?id=",
    },
    //attendance routes added by karan
    CLASS_TAKEN_REPORT: "v1/admin/attendance/class-taken-report",
    TIMETABLE_ON_DATE: "v1/admin/attendance/today-time-table",
    LIST_TODAY_CLASS: "v1/admin/attendance/list-classes-by-staff-and-date",
    LIST_SUBJECTS_AS_PER_COURSE:
      "v1/admin/attendance/list-subjects-as-per-course",
    LIST_STAFFS_AS_PER_SUBJECT:
      "v1/admin/attendance/list-staffs-as-per-subject",
    LIST_SUBJECTS_AS_PER_STAFFS:
      "v1/admin/attendance/list-subjects-as-per-staffs",
    LIST_CLASS_TAKEN_COUNT: "v1/admin/attendance/list-class-taken-count",
    BATCH_LIST_STUDENT_TC: "v1/admin/student/batch-list-tc",

    GET_MONTHLY_TIMETABLE: "v1/admin/attendance/monthly-wise-time-table",
    GET_MONTHLY_ATT_DATA: "v1/admin/attendance/monthly-wise-att-data",

    STUDENT_STRENGTH_REPORT_II:
      "v1/admin/student-strength-report/statistics-strength",

    HSC_MARKS_UPDATE: "v1/admin/hscmarks/update",
    HSC_MARK_LIST: "v1/admin/student/hscmarks/list",
    LIST_HSC_SUBJECTS: "v1/admin/student/hscmarks/list-subject",
    UPDATEMARK: "v1/admin/student/hscmarks/update-mark",
    UPDATE_STUDENT_SCHOOL_DETAILS:
      "v1/admin/student/hscmarks/update-student-school-details",

    UPDATE_UG_MARK: "v1/admin/student/hscmarks/update-ug-marks",
    UG_MARK_LIST: "v1/admin/student/hscmarks/list-ug",

    SAVE_HSC_SUBJECT: "v1/admin/student/hscmarks/save-subject",
    SAVE_UG_SUBJECT: "v1/admin/student/hscmarks/save-ug-subject",
    LIST_UG_SUBJECTS: "v1/admin/student/hscmarks/list-ug-subject",
    MEMBERCATEGORY: "v1/admin/student/service_request/list-member-category",
    MEMBERSUBCATEGORY:
      "v1/admin/student/service_request/list-member-subcategory",
    SAVE_MEMBER_REGISTRATION:
      "v1/admin/student/service_request/save-member-registration",
    LIST_MEMBER_REGISTRATION:
      "v1/admin/student/service_request/list-member-registration",
    LIST_STUDENTS_REGISTER_MEMBER:
      "v1/admin/student/service_request/list-student-registration",
    REMOVE_STUDENTS_REGISTER_MEMBER:
      "v1/admin/student/service_request/delete-student-registration",
    UPDATE_STUDENTS_REGISTER_MEMBER:
      "v1/admin/student/service_request/update-student-registration",
  },
  HOSTEL: {
    SAVE_FLOOR: "v1/admin/hostel/floor/save",
    LIST_FLOORS: "v1/admin/hostel/floor/list",
    DELETE_FLOOR: "v1/admin/hostel/floor/delete",
    UPDATE_FLOOR: "v1/admin/hostel/floor/update",

    LIST_ROOMS: "v1/admin/hostel/room/list",
  },
  SUSPENSION: {
    SAVE: "v1/admin/student/suspension/save",
    LIST: "v1/admin/student/suspension/list",
    REVOKE: "v1/admin/student/suspension/revoke",
    DELETE: "v1/admin/student/suspension/delete",
  },
  SETTINGS: {
    SAVE_CASHBOOKS: "v1/admin/cashbooks/save",
    LIST_CASHBOOKS: "v1/admin/cashbooks/list",
    DELETE_CASHBOOK: "v1/admin/cashbooks/delete",
    UPDATE_CASHBOOK: "v1/admin/cashbooks/update",

    SAVE_GATEWAY_MASTER: "v1/admin/gateways/save",
    LIST_GATEWAY_MASTER: "v1/admin/gateways/list",
    DELETE_GATEWAY_MASTER: "v1/admin/gateways/delete",
    UPDATE_GATEWAY_MASTER: "v1/admin/gateways/update",

    SAVE_GATEWAY_SUBACCOUNT: "v1/admin/gateway/sub/save",
    LIST_GATEWAY_SUBACCOUNT: "v1/admin/gateway/sub/list",
    DELETE_GATEWAY_SUBACCOUNT: "v1/admin/gateway/sub/delete",
    UPDATE_GATEWAY_SUBACCOUNT: "v1/admin/gateway/sub/update",

    SAVE_COLLEGE: "v1/admin/college/save",
    LIST_COLLEGE: "v1/admin/college/list",
    DELETE_COLLEGE: "v1/admin/college/delete",
    UPDATE_COLLEGE: "v1/admin/college/update",

    SAVE_COLLEGE_YEAR: "v1/admin/collegeyear/save",
    LIST_COLLEGE_YEAR: "v1/admin/collegeyear/list",
    DELETE_COLLEGE_YEAR: "v1/admin/collegeyear/delete",
    UPDATE_COLLEGE_YEAR: "v1/admin/collegeyear/update",

    SAVE_USERROLE: "v1/admin/rbac/userrole/save",
    LIST_USERROLE: "v1/admin/rbac/userrole/list",
    DELETE_USERROLE: "v1/admin/rbac/userrole/delete",
    UPDATE_USERROLE: "v1/admin/rbac/userrole/update",

    SAVE_ROLE_MODULE: "v1/admin/rbac/roleaccess/save-module",
    DELETE_ROLE_MODULE: "v1/admin/rbac/roleaccess/delete-module",
    UPDATE_ROLE_MODULE: "v1/admin/rbac/roleaccess/update-module-access",
    UPDATE_USER_ACCESS: "v1/admin/rbac/roleaccess/update-user-access",
    LIST_ROLE_ACCESS: "v1/admin/rbac/roleaccess/access-list",
    UPDATE_ROLE_ACCESS: "v1/admin/rbac/roleaccess/update-role-access",
    LIST_ROLE_PERMISSIONS: "v1/admin/rbac/roleaccess/list-role-permissions",

    SAVE_BATCH: "v1/admin/settings/batch/save",
    LIST_BATCH: "v1/admin/settings/batch/list",
    DELETE_BATCH: "v1/admin/settings/batch/delete",
    UPDATE_BATCH: "v1/admin/settings/batch/update",
    UPDATE_SEMESTER_DATES: "v1/admin/settings/batch/update-semester-dates",
    SEMESTER_DATES: "v1/admin/settings/batch/semester-dates",
    COLLEGE_YEARS: "v1/admin/settings/batch/list-college-years",

    SAVE_DEPARTMENT: "v1/admin/settings/departments/save",
    LIST_DEPARTMENT: "v1/admin/settings/departments/list",
    DELETE_DEPARTMENT: "v1/admin/settings/departments/delete",
    UPDATE_DEPARTMENT: "v1/admin/settings/departments/update",
    UPDATE_DEPARTMENT_COURSE:
      "v1/admin/settings/departments/update-dept-course",

    SAVE_SUBJECT_NATURE: "v1/admin/settings/subjectnature/save",
    LIST_SUBJECT_NATURE: "v1/admin/settings/subjectnature/list",
    REMOVE_SUBJECT_NATURE: "v1/admin/settings/subjectnature/delete",
    UPDATE_SUBJECT_NATURE: "v1/admin/settings/subjectnature/update",

    SAVE_SUBJECT_SCHEME: "v1/admin/settings/subjectscheme/save",
    LIST_SUBJECT_SCHEME: "v1/admin/settings/subjectscheme/list",
    REMOVE_SUBJECT_SCHEME: "v1/admin/settings/subjectscheme/delete",
    UPDATE_SUBJECT_SCHEME: "v1/admin/settings/subjectscheme/update",

    SAVE_QUALIFICATION_CATEGORY: "v1/admin/settings/qualificationcategory/save",
    LIST_QUALIFICATION_CATEGORY: "v1/admin/settings/qualificationcategory/list",
    REMOVE_QUALIFICATION_CATEGORY:
      "v1/admin/settings/qualificationcategory/delete",
    UPDATE_QUALIFICATION_CATEGORY:
      "v1/admin/settings/qualificationcategory/update",

    SAVE_DESIGNATION_ADDITIONAL: "v1/admin/settings/designationaddtional/save",
    LIST_DESIGNATION_ADDITIONAL: "v1/admin/settings/designationaddtional/list",
    REMOVE_DESIGNATION_ADDITIONAL:
      "v1/admin/settings/designationaddtional/delete",
    UPDATE_DESIGNATION_ADDITIONAL:
      "v1/admin/settings/designationaddtional/update",

    SAVE_TRAINING: "v1/admin/settings/trainingundergone/save",
    LIST_TRAINING: "v1/admin/settings/trainingundergone/list",
    REMOVE_TRAINING: "v1/admin/settings/trainingundergone/delete",
    UPDATE_TRAINING: "v1/admin/settings/trainingundergone/update",

    SAVE_EXAMINATION_CATEGORY: "v1/admin/settings/examinationcategory/save",
    LIST_EXAMINATION_CATEGORY: "v1/admin/settings/examinationcategory/list",
    REMOVE_EXAMINATION_CATEGORY: "v1/admin/settings/examinationcategory/delete",
    UPDATE_EXAMINATION_CATEGORY: "v1/admin/settings/examinationcategory/update",

    SAVE_EXPERIENCE_DESIGNATION: "v1/admin/settings/experiencedesignation/save",
    LIST_EXPERIENCE_DESIGNATION: "v1/admin/settings/experiencedesignation/list",
    REMOVE_EXPERIENCE_DESIGNATION:
      "v1/admin/settings/experiencedesignation/delete",
    UPDATE_EXPERIENCE_DESIGNATION:
      "v1/admin/settings/experiencedesignation/update",

    SAVE_SUBJECT: "v1/admin/settings/subjects/save",
    LIST_SUBJECT: "v1/admin/settings/subjects/list",
    DELETE_SUBJECT: "v1/admin/settings/subjects/delete",
    UPDATE_SUBJECT: "v1/admin/settings/subjects/update",
    IMPORT_SUBJECTS: "v1/admin/settings/subjects/import",

    SAVE_ELECTIVE_SETTINGS: "v1/admin/settings/subjects/save-elective-settings",
    LIST_ELECTIVE_SETTINGS: "v1/admin/settings/subjects/elective-settings",
    LIST_ELECTIVE_POLLING_SUBJECTS:
      "v1/admin/settings/subjects/elective-polling-subjects",
    DELETE_ELECTIVE_SETTINGS:
      "v1/admin/settings/subjects/delete-elective-settings",

    SAVE_COURSE_SUBJECT: "v1/admin/settings/courseSubjects/save",
    LIST_COURSE_SUBJECT: "v1/admin/settings/courseSubjects/list",
    DELETE_COURSE_SUBJECT: "v1/admin/settings/courseSubjects/delete",
    UPDATE_COURSE_SUBJECT: "v1/admin/settings/courseSubjects/update",
    UPDATE_COURSE_FEE: "v1/admin/settings/courseSubjects/update-course-fee",
    SAVE_STAFFSUBJECT: "v1/admin/settings/staffsubject/save",
    LIST_STAFFSUBJECT: "v1/admin/settings/staffsubject/list",
    DELETE_STAFFSUBJECT: "v1/admin/settings/staffsubject/delete",
    UPDATE_STAFFSUBJECT: "v1/admin/settings/staffsubject/update",
    BULK_SAVE_STAFFSUBJECT: "v1/admin/settings/staffsubject/bulk-save",

    LIST_LEAVESETTINGS: "v1/admin/settings/leavesetting/list",
    SAVE_LEAVESETTINGS: "v1/admin/settings/leavesetting/save",
    UPDATE_LEAVESETTINGS: "v1/admin/settings/leavesetting/update",
    REMOVE_LEAVESETTINGS: "v1/admin/settings/leavesetting/delete",

    SAVE_TIMETABLE: "v1/admin/settings/timetable/save-time-table",
    UPDATE_TIMETABLE: "v1/admin/settings/timetable/update-time-table",
    GET_TIMETABLE: "v1/admin/settings/timetable/get-timetable",
    REMOVE_TIMETABLE: "v1/admin/settings/timetable/remove",

    CLASS_TIME_TABLES: "v1/admin/settings/timetable/class-time-tables",
    SAVE_ELECTIVE_TIMETABLE:
      "v1/admin/settings/timetable/save-elective-timetable",
    UPDATE_ELECTIVE_TIMETABLE:
      "v1/admin/settings/timetable/update-elective-timetable",
    DELETE_ELECTIVE_TIMETABLE:
      "v1/admin/settings/timetable/delete-elective-timetable",

    LIST_COMMUNITY: "v1/set/community",
    LIST_RELIGIONS: "v1/set/religions",
    LIST_OCCUPATION_CATEGORIES: "v1/set/occupation-categories",

    LIST_USER_LOGS: "v1/admin/logs/list-all",

    LIST_CLASS_TIME: "v1/admin/classtime/list-class-time",
    ADD_CLASS_TIME: "v1/admin/classtime/add-class-time",
    UPDATE_CLASS_TIME: "v1/admin/classtime/update-class-time",
    LIST_COURSES: "v1/admin/classtime/list-courses",

    COURSE_SUBJECT_BULK_SAVE: "v1/admin/settings/coursesubject/bulk-save",
    LIST_COURSE_SUBJECTS: "v1/admin/settings/coursesubject/list-by-course",
    DELETE_ALLOCATED_COURSE_SUBJECTS:
      "v1/admin/settings/coursesubject/delete-allocation",
  },
  FEE_CATEGORY: {
    SAVE_CATEGORY: "v1/admin/fees/category/save",
    LIST_CATEGORY: "v1/admin/fees/category/list",
    DELETE_CATEGORY: "v1/admin/fees/category/delete",
    UPDATE_CATEGORY: "v1/admin/fees/category/update",
    UPDATE_MOBILE_SHOW: "v1/admin/fees/category/update-mobile-show",

    SAVE_FEE_GROUP: "v1/admin/fees/group/save",
    LIST_FEE_GROUP: "v1/admin/fees/group/list",
    DELETE_FEE_GROUP: "v1/admin/fees/group/delete",
    UPDATE_FEE_GROUP: "v1/admin/fees/group/update",
    UPDATE_FEE_GROUP_CATEGORY_LIST: "v1/admin/fees/group/update-list-id",

    SAVE_FEECATEGORY_GROUP: "v1/admin/fees/categorygroup/save",
    LIST_FEECATEGORY_GROUP: "v1/admin/fees/categorygroup/list",
    DELETE_FEECATEGORY_GROUP: "v1/admin/fees/categorygroup/delete",
    UPDATE_FEECATEGORY_GROUP: "v1/admin/fees/categorygroup/update",
    UPDATE_FEECATEGORY_GROUP_CATEGORY_LIST:
      "v1/admin/fees/categorygroup/update-list-id",

    SAVE_PAYMENT_METHOD: "v1/admin/fees/payment_method/save",
    LIST_PAYMENT_METHOD: "v1/admin/fees/payment_method/list",
    DELETE_PAYMENT_METHOD: "v1/admin/fees/payment_method/delete",
    UPDATE_PAYMENT_METHOD: "v1/admin/fees/payment_method/update",

    CATEGORY_LIST_WITH_STUDENT_COUNT:
      "v1/admin/fees/assign/category-with-student-count",
    SAVE_COURSE_WISE_FEE: "v1/admin/fees/assign/save-course-wise-fee",
    SAVE_STUDENT_WISE_FEE: "v1/admin/fees/assign/save-student-wise-fee",
    SAVE_STUDENT_WISE_FEE_V2: "v1/admin/fees/assign/save-student-wise-feev2",

    SAVE_COURSE_WISE_FEE_ADJUST: "v1/admin/fees/assign/save-course-wise-adjust",
    SAVE_STUDENT_WISE_FEE_ADJUST:
      "v1/admin/fees/assign/save-student-wise-adjust",
    STUDENT_PENDING_BY_CATEGORY:
      "v1/admin/fees/assign/student-pending-by-category",
    STUDENT_BULK_FEE_IMPORT: "v1/admin/fees/assign/bulk-fee-import",
  },
  FEES: {
    BILL_NO: "v1/admin/fees/payment/bill-no",
    LIST_PAYMENT: "v1/admin/fees/payment/student-bills",
    SAVE_STUDENT_PAYMENT: "v1/admin/fees/payment/save-student-payment",
    LIST_STUDENT_PAYMENT: "v1/admin/fees/payment/list-bills",
    VIEW_STUDENT_BILL: "v1/admin/fees/payment/get-bill",
    DELETE_ASSIGNED_FEE: "v1/admin/fees/payment/delete-fee-record",
    BULK_DELETE_ASSIGNED_FEE: "v1/admin/fees/payment/delete-bulk-fee-record",
    UPDATE_PAID_PAYMENT_DATE: "v1/admin/fees/payment/update-bill-date",
    DELETE_PAYMENT: "v1/admin/fees/payment/delete-payment",
    SETTLEMENT_TO_PAYMENT: "v1/admin/fees/payment/settlement-to-payment",
    DELETE_STUDENT_CONCESSION_ENTRY: "v1/admin/fees/payment/delete-concession",
    UPDATE_STUDENT_CONCESSION_LOG:
      "v1/admin/fees/payment/update-concession-log",
    SAVE_STUDENT_BULK_PAYMENT:
      "v1/admin/fees/payment/save-student-bulk-payment",

    CHALLAN_BILL_NO: "v1/admin/fees/challan/bill-no",
    CHALLAN_LIST_PAYMENT: "v1/admin/fees/challan/student-bills",
    CHALLAN_SAVE_STUDENT_PAYMENT: "v1/admin/fees/challan/save-student-payment",
    CHALLAN_LIST_STUDENT_PAYMENT: "v1/admin/fees/challan/list-bills",
    CHALLAN_VIEW_STUDENT_BILL: "v1/admin/fees/challan/get-bill",
    CHALLAN_DELETE_ASSIGNED_FEE: "v1/admin/fees/challan/delete-fee-record",

    FEE_CATEGORY_REPORT: "v1/admin/fees/reports/category-reoprt",
    FEE_CATEGORY_SUMMARY: "v1/admin/fees/reports/category-summary",
    CLASS_WISE_PENDING: "v1/admin/fees/reports/class-wise-pending",
    CUSTOM_FEE_REPORT: "v1/admin/fees/reports/custom-fee-report",
    CUSTOM_FEE_CATEGORY_REPORT: "v1/admin/fees/reports/custom-category-reoprt",
    FEE_CONCESSION_REPORT: "v1/admin/fees/reports/concession-report",
    FEE_CASHBOOK_ABSTRACT: "v1/admin/fees/reports/cashbook-abstract",

    V2_REPORTS: {
      COURSE_PENDING_SUMMARY: "v1/admin/fees/reportsv2/course-pending-summary",
    },

    LIST_TEMPLATES: "v1/admin/fees/template/list-all",
    REMOVE_TEMPLATE: "v1/admin/fees/template/remove-data",
    SAVE_TEMPLATE: "v1/admin/fees/template/save-data",
    UPDATE_TEMPLATE: "v1/admin/fees/template/update-data",
    SAVE_CUSTOM_FEE_TEMPLATE: "v1/admin/fees/template/save-custom-fee",
    LIST_CUSTOM_FEE_TEMPLATES: "v1/admin/fees/template/list-custom-fees",

    CANCEL_BILL: "v1/admin/fees/payment/cancel-bill",

    SAVE_BANK: "v1/admin/fees/bank/save",
    LIST_BANKS: "v1/admin/fees/bank/list",
    DELETE_BANK: "v1/admin/fees/bank/delete",
    UPDATE_BANK: "v1/admin/fees/bank/update",

    CREATE_ACCOUNT_HEAD: "v1/admin/fees/bank/create-account",
    ACCOUNT_HEAD_LIST: "v1/admin/fees/bank/account-list",
    UPDATE_ACCOUNT_HEAD: "v1/admin/fees/bank/update-account",
    UPDATE_ACCOUNT_BANK_HEAD: "v1/admin/store/update-accountbankhead",
    SAVE_FINE: "v1/admin/fees/fine/save",
    LIST_FINE_LOGS: "v1/admin/fees/fine/list",
    SAVE_AUTO_FINE_CONFIG: "v1/admin/fees/fine/save-auto-fine-config",
    LIST_AUTO_FINE_CONFIG: "v1/admin/fees/fine/list-auto-fine-config",
    DELETE_AUTO_FINE_CONFIG: "v1/admin/fees/fine/delete-auto-fine-config",
    AUTO_FINE_CONFIG_STATUS_CHANGE:
      "v1/admin/fees/fine/auto-fine-status-change",
    UPDATE_AUTO_FINE_CONFIG: "v1/admin/fees/fine/update-auto-fine-config",

    SAVE_AUTO_DELETE_CONFIG: "v1/admin/fees/deleteconfig/save",
    LIST_AUTO_DELETE_CONFIG: "v1/admin/fees/deleteconfig/list",
    DELETE_AUTO_DELETE_CONFIG: "v1/admin/fees/deleteconfig/delete",
    AUTO_DELETE_CONFIG_STATUS_CHANGE:
      "v1/admin/fees/deleteconfig/status-change",
    UPDATE_AUTO_DELETE_CONFIG: "v1/admin/fees/deleteconfig/update",

    SAVE_HOSTEL_FEE_IMPORT: "v1/admin/hostel/fee/save-import",

    ONLINE_PAY_LIST: "v1/admin/fees/onlinepay/list",
    ONLINE_PAY_LIST_DELETE: "v1/admin/fees/onlinepay/delete",
    ONLINE_PAY_TRACK_STATUS: "v1/admin/fees/onlinepay/track-order-status",
    ONLINE_PAY_TRACK_SPLIT_STATUS: "v1/admin/fees/onlinepay/track-split-status",
    ONLINE_PAY_UPDATE_REF_NO: "v1/admin/fees/onlinepay/update-refno",

    SAVE_CREDIT: "v1/admin/fees/credit/save",
    LIST_CREDIT: "v1/admin/fees/credit/list",
    DELETE_CREDIT: "v1/admin/fees/credit/delete",
    CREDIT_BALANCE_LIST: "v1/admin/fees/credit/balance-list",
    SAVE_CREDIT_TRANSFER: "v1/admin/fees/credit/save-credit-transfer",

    SAVE_STUDENT_WISE_CONCESSION:
      "v1/admin/fees/payment/save-student-concession",
  },
  FEESV2: {
    FEES_VERIFICATION_REPORT: "v1/admin/fees/reportsv2/fee-verification",

    OVER_ALL_SUMMARY: "v1/admin/fees/reportsv2/overall-summary",
    OVER_ALL_BATCH_WISE_SUMMARY: "v1/admin/fees/reportsv2/batch-summary",
    OVER_ALL_DAY_WISE_SUMMARY: "v1/admin/fees/reportsv2/day-summary",
    OVER_ALL_CATEGORY_SUMMARY: "v1/admin/fees/reportsv2/category-summary",
    OVER_ALL_BANK_SUMMARY: "v1/admin/fees/reportsv2/bank-summary",
  },
  RECEIPT_PAYMENTS: {
    RECEIPT_NO: "v1/admin/fees/recpay/bill-no",
    SAVE_RECPAY: "v1/admin/fees/recpay/save",
    LIST_RECPAY: "v1/admin/fees/recpay/list-bills",
    GET_RECPAY_BILL: "v1/admin/fees/recpay/get-bill",
    CANCEL_RECPAY_BILL: "v1/admin/fees/recpay/cancel-bill",
    _RECPAY_CATEGORY_SUMMARY: "v1/admin/fees/recpay/category-summary",

    SAVE_RECPAY_CHALLAN: "v1/admin/fees/recchallan/save",
    LIST_RECPAY_CHALLAN: "v1/admin/fees/recchallan/list-bills",
    GET_RECPAY_BILL_CHALLAN: "v1/admin/fees/recchallan/get-bill",
    CANCEL_RECPAY_BILL_CHALLAN: "v1/admin/fees/recchallan/cancel-bill",
  },
  TRANSPORT: {
    SAVE_VEHICLE: "v1/admin/vehicle/transport/save-data",
    LIST_VEHICLES: "v1/admin/vehicle/transport/list-data",
    REMOVE_VEHICLE: "v1/admin/vehicle/transport/remove-data",
    UPDATE_VEHICLE: "v1/admin/vehicle/transport/update-data",

    SAVE_DESTINATION: "v1/admin/vehicle/destination/save-data",
    LIST_DESTINATION: "v1/admin/vehicle/destination/list-data",
    REMOVE_DESTINATION: "v1/admin/vehicle/destination/remove-data",
    UPDATE_DESTINATION: "v1/admin/vehicle/destination/update-data",

    LIST_STUDENT_REQUEST: "v1/admin/vehicle/student-request/list",
    DELETE_STUDENT_REQUEST: "v1/admin/vehicle/student-request/delete",
    TRASNPORT_REQUEST_STATUS_UPDATE:
      "v1/admin/vehicle/student-request/status-update",
  },
  PAYROLL: {
    SAVE_LEAVE: "v1/admin/payroll/save_data",
    LIST_EMPLOYEES_LEAVES: "v1/admin/payroll/list_data",
    UPDATE_EMPLOYEES_LEAVE: "v1/admin/payroll/update_data",
    UPDATECHECKED_EMPLOYEES_LEAVE: "v1/admin/payroll/update_datachecked",

    REMOVE_EMPLOYEES_LEAVE: "v1/admin/payroll/remove_data",
    EMP_LEAVE_LIST: "v1/admin/payroll/list_substution",

    SAVE_PERMISSION: "v1/admin/payroll/save_data_permission",
    LIST_EMPLOYEES_PERMISSION: "v1/admin/payroll/list_data_permission",
    UPDATE_EMPLOYEES_PERMISSION: "v1/admin/payroll/update_data_permission",
    REMOVE_EMPLOYEES_PERMISSION: "v1/admin/payroll/remove_permission",
    UPDATECHECKED_EMPLOYEES_PERMISSION:
      "v1/admin/payroll/update_data_permissionchecked",
    UPDATECHECKED_EMPLOYEES_ODPERMISSION:
      "v1/admin/payroll/update_data_odpermissionchecked",

    SAVE_ODPERMISSION: "v1/admin/payroll/save_data_odpermission",
    LIST_EMPLOYEES_ODPERMISSION: "v1/admin/payroll/list_data_odpermission",
    UPDATE_EMPLOYEES_ODPERMISSION: "v1/admin/payroll/update_data_odpermission",
    REMOVE_EMPLOYEES_ODPERMISSION: "v1/admin/payroll/remove_data_odpermission",

    GET_PERMISSION_COUNT: "v1/admin/payroll/get_permission_count",
    GET_LEAVE_COUNT: "v1/admin/payroll/get_leave_count",

    LIST_BREAKUP_HEADS: "v1/admin/payroll/break/list_breakup_heads",
    SAVE_HEAD: "v1/admin/payroll/break/save_head",
    UPDATE_HEAD: "v1/admin/payroll/break/update_head",
    REMOVE_HEAD: "v1/admin/payroll/break/remove_head",
    GET_BREAK_HEAD: "v1/admin/payroll/grade/get_breakhead",

    SAVE_GRADE: "v1/admin/payroll/grade/save_data_setup",
    LIST_SETUP: "v1/admin/hrmgt/grade/list_grade_settings",
    LIST_SETUPS: "v1/admin/payroll/grade/list_data_setup",
    UPDATE_SETUP: "v1/admin/payroll/grade/update_data_setup",
    REMOVE_SETUP: "v1/admin/payroll/grade/remove_data_setup",
    REMOVE_SALARY_SETUP: "v1/admin/payroll/grade/remove_salary_setup",

    SAVE_SALARY: "v1/admin/payroll/salary/save_salary",
    LIST_SALARY: "v1/admin/payroll/salary/salary_list",
    UPDATE_SALARY: "v1/admin/payroll/salary/update_salary",
    REMOVE_SALARY: "v1/admin/payroll/salary/remove_salary",
    LIST_SALARY_SETTINGS: "v1/admin/payroll/salary/salary_settings_list",
    LIST_SALARY_DATA: "v1/admin/payroll/salary/salary_list_data",
    LIST_SALARY_DATAALL: "v1/admin/payroll/salary/salary_list_dataall",

    LIST_SALARY_DEDUCTION: "v1/admin/payroll/salary/salary_deduction_list",
    SAVE_SALARY_DEDUCTION: "v1/admin/payroll/salary/save_salary_deduction",
    UPDATE_SALARY_DEDUCTION: "v1/admin/payroll/salary/update_salary_deduction",
    REMOVE_SALARY_DEDUCTION: "v1/admin/payroll/salary/remove_salary_deduction",

    SAVE_SALARY_ADVANCE: "v1/admin/payroll/salary/save_salary_advance",
    LIST_SALARY_ADVANCE: "v1/admin/payroll/salary/salary_advance_list",
    REMOVE_SALARY_ADVANCE: "v1/admin/payroll/salary/remove_advance_salary",
    UPDATE_SALARY_ADVANCE: "v1/admin/payroll/salary/update_advance_salary",

    LIST_EMPLOYEE_SALARY_DETAILS:
      "v1/admin/payroll/salary/employee_salary_details",
    LIST_EMPLOYEE_DEDUCTION_DETAILS:
      "v1/admin/payroll/salary/employee_deduction_details",
    LIST_EMPLOYEE_DEDUCTION_PRINT:
      "v1/admin/payroll/salary/employee_deduction_details_print",
    EMPLOYEE_SALARY_SETTINGS:
      "v1/admin/payroll/salary/employee_salary_settings",

    SAVE_MONTH_SALARY: "v1/admin/payroll/salary/save_employee_month_salary",
    LIST_BIOMETRIC_SUMMARY: "v1/admin/payroll/salary/get_monthly_summary",
    LIST_DEVICELOG: "v1/admin/payroll/salary/device_log_month",
    UPDATE_PAYROLL_ALLOWANCE:
      "v1/admin/payroll/salary/payroll_allowance_update",
    EMPLOYEES_LEAVE_PERMISSION:
      "v1/admin/payroll/salary/permission_leave_count",
    SAVE_RULES: "v1/admin/payroll/salary/save_rules",
    LIST_RULES: "v1/admin/payroll/salary/list_rules",
    PAYROLL_REPORTS: "v1/admin/payroll/payroll_reports",
    GET_LEAVE_DETAILS: "v1/admin/payroll/payroll_leave_reports",
    GET_PERMISSION_EMP_DETALIS:
      "v1/admin/payroll/payroll_permission_empreports",
    GET_PERMISSION_DETAILS: "v1/admin/payroll/payroll_permission_reports",
    GET_ODPERMISSION_EMP_DETALIS:
      "v1/admin/payroll/payroll_odpermission_empreports",
    GET_ODPERMISSION_DETAILS: "v1/admin/payroll/payroll_odpermission_reports",
    GET_SURRENDER_LEAVE: "v1/admin/payroll/get_surrender_leaves",
    SURRENDER_LEAVE: "v1/admin/payroll/employee_surrender_leaves",
    LEAVE_ALLOTMENT: "v1/admin/payroll/employee_leave_allotment",
    SALARY_ALLOTMENT: "v1/admin/payroll/employee_salary_allotment",
    LIST_SALARY_INCREMENTAMOUNT: "v1/admin/payroll/list_salary_allotment",
    LIST_EMPLOYEES: "v1/admin/payroll/list_data_emp",
    LIST_EMPLOYEES_V2: "v1/admin/hrmgt/employee/list_data_emp",

    GET_ABSTRACTLIST: "v1/admin/payroll/payroll_abstractlist",
    GET_CHECKUPLIST: "v1/admin/payroll/payroll_checkuplist",
    GET_BANKLIST: "v1/admin/payroll/payroll_banklist",
    GET_PFREPORT_LIST: "v1/admin/payroll/payroll_pfreport",
    GET_ESIREPORT_LIST: "v1/admin/payroll/payroll_esireport",
    GET_PAYSLIP: "v1/admin/payroll/payroll_salary_slip",
    GET_GENERATE_PAYSLIP: "v1/admin/payroll/payroll_salary_generateslip",

    GET_EMPLOYEE_REPORT: "v1/admin/payroll/payroll_employee_reports",
    GET_BONUSLIST: "v1/admin/payroll/payroll_bonuslist",
    SAVE_BONUSLIST: "v1/admin/payroll/payroll_save_bonus",
    SURRENDER_SALARY: "v1/admin/payroll/payroll_surrender_leave",
    EMP_ACADEMIC_COURSE: "v1/admin/payroll/list_emp_dept",
    TIME_TABLE_FOR_SUBSTITUTE: "v1/admin/payroll/list_substitute_time_table",
    SAVE_LEAVE_SUBSTIUTE: "v1/admin/payroll/save_substitute_time_table",
    TIME_TABLE_FOR_SAVE_LIST: "v1/admin/payroll/list_substitute_staff",
    LEAVE_ALLOTMENT_LIST: "v1/admin/payroll/list_leaveallotment",

    LIST_PAYROLL_EMPLOYEE_SLIP: "v1/admin/payroll/list_employeepay_slip",
    DELETE_PAYROLL_EMPLOYEE_SLIP: "v1/admin/payroll/delete_employeepay_slip",
    UPDATE_MOBILE_VIEW: "v1/admin/payroll/update_employeepay_slipmobileview",

    IMPORT_STUDENT: "v1/admin/payroll/salary/save_import",
  },
  HR: {
    V2: {
      EMPLOYEE_SAVE: "v1/admin/employee_v2/save",
      VIEW_PASSWORDS: "v1/admin/employee_v2/passwords",
      UPDATE_PERSONAL: "v1/admin/employee_v2/update-personal",
      UPDATE_COMMUNICATION: "v1/admin/employee_v2/update-communication",
      UPDATE_BANK: "v1/admin/employee_v2/update-bank",
      UPDATE_QUALI: "v1/admin/employee_v2/update-quali",
      UPDATE_RESEARCH: "v1/admin/employee_v2/update-research",
      ADD_EXPERIENCE: "v1/admin/employee_v2/add-experience",
      UPDATE_EXPERIENCE: "v1/admin/employee_v2/update-experience",
      UPDATE_EXAMINATION: "v1/admin/employee_v2/update-examination",
      UPDATE_THESIS: "v1/admin/employee_v2/update-thesis",
      UPDATE_DOCTORAL: "v1/admin/employee_v2/update-doctoral",
      LIST_EXAMINATION: "v1/admin/employee_v2/list-examination",
      LIST_EXPERIENCE: "v1/admin/employee_v2/list-experience",
      LIST_RESEARCH: "v1/admin/employee_v2/list-research",
      LIST_THESIS: "v1/admin/employee_v2/list-thesis",
      LIST_DOCTORAL: "v1/admin/employee_v2/list-doctoral",
      LIST_STAFF_SUBJECT_WITH_MARKS:
        "v1/admin/employee_v2/list-staff-subject-marks",
      UPDATE_PASSWORD: "v1/admin/employee_v2/update-pass",

      COUNT_RESEARCH_EXPERIENCE:
        "v1/admin/employee_v2/count-research-experience",
      COUNT_COLLEGE_EXPERIENCE: "v1/admin/employee_v2/count-college-experience",
      DELETE_EXPERIENCE: "v1/admin/employee_v2/delete-experience",
      DELETE_DOCTORAL: "v1/admin/employee_v2/delete-doctoral",
      DELETE_THESIS: "v1/admin/employee_v2/delete-thesis",
      DELETE_EXAMINATION: "v1/admin/employee_v2/delete-examination",
      SAVE_QUALIFICATION: "v1/admin/employee_v2/save-qualification",
      UPDATE_QUALIFICATION: "v1/admin/employee_v2/update-qualification",
      LIST_QUALIFICATION: "v1/admin/employee_v2/list-qualification",
      DELETE_QUALIFICATION: "v1/admin/employee_v2/delete-qualification",
    },

    EMP_INCHARGE: {
      SAVE_INCHARGE: "v1/admin/employee_v3/save",
      UPDATE_INCHARGE: "v1/admin/employee_v3/update",
      INCHARGE_COUNT: "v1/admin/employee_v3/count-incharge",
      LIST_INCHARGE: "v1/admin/employee_v3/list",
      DELETE_INCHARGE: "v1/admin/employee_v3/delete",
      SAVE_POSITION: "v1/admin/employee_v3/save-personalposition",
      UPDATE_POSITION: "v1/admin/employee_v3/update-personalposition",
      LIST_POSITION: "v1/admin/employee_v3/list-personalposition",
      DELETE_POSITION: "v1/admin/employee_v3/delete-personalposition",

      SAVE_RESEARCH: "v1/admin/employee_v3/save-research",
      UPDATE_RESEARCH: "v1/admin/employee_v3/update-research",
      LIST_RESEARCH: "v1/admin/employee_v3/list-research",
      DELETE_RESEARCH: "v1/admin/employee_v3/delete-research",

      SAVE_RESEARCH_PROJECTS: "v1/admin/employee_v3/save-researchprojects",
      UPDATE_RESEARCH_PROJECTS: "v1/admin/employee_v3/update-researchprojects",
      LIST_RESEARCH_PROJECTS: "v1/admin/employee_v3/list-researchprojects",
      DELETE_RESEARCH_PROJECTS: "v1/admin/employee_v3/delete-researchprojects",

      SAVE_AWARDS: "v1/admin/employee_v3/save-awards",
      UPDATE_AWARDS: "v1/admin/employee_v3/update-awards",
      LIST_AWARDS: "v1/admin/employee_v3/list-awards",
      DELETE_AWARDS: "v1/admin/employee_v3/delete-awards",

      SAVE_PRESENTATION: "v1/admin/employee_v3/save-presentation",
      UPDATE_PRESENTATION: "v1/admin/employee_v3/update-presentation",
      LIST_PRESENTATION: "v1/admin/employee_v3/list-presentation",
      DELETE_PRESENTATION: "v1/admin/employee_v3/delete-presentation",

      SAVE_PUBLICATION: "v1/admin/employee_v3/save-publication",
      UPDATE_PUBLICATION: "v1/admin/employee_v3/update-publication",
      LIST_PUBLICATION: "v1/admin/employee_v3/list-publication",
      DELETE_PUBLICATION: "v1/admin/employee_v3/delete-publication",

      SAVE_DEPT_EXAMINATION: "v1/admin/employee_v3/save-deptexam",
      UPDATE_DEPT_EXAMINATION: "v1/admin/employee_v3/update-deptexam",
      LIST_DEPT_EXAMINATION: "v1/admin/employee_v3/list-deptexam",
      DELETE_DEPT_EXAMINATION: "v1/admin/employee_v3/delete-deptexam",

      SAVE_ACHIEVEMENT: "v1/admin/employee_v3/save-achievement",
      UPDATE_ACHIEVEMENT: "v1/admin/employee_v3/update-achievement",
      LIST_ACHIEVEMENT: "v1/admin/employee_v3/list-achievement",
      DELETE_ACHIEVEMENT: "v1/admin/employee_v3/delete-achievement",

      SAVE_FACULTY: "v1/admin/employee_v3/save-faculty",
      UPDATE_FACULTY: "v1/admin/employee_v3/update-faculty",
      LIST_FACULTY: "v1/admin/employee_v3/list-faculty",
      DELETE_FACULTY: "v1/admin/employee_v3/delete-faculty",

      SAVE_TRAINING: "v1/admin/employee_v3/save-training",
      UPDATE_TRAINING: "v1/admin/employee_v3/update-training",
      LIST_TRAINING: "v1/admin/employee_v3/list-training",
      DELETE_TRAINING: "v1/admin/employee_v3/delete-training",

      SAVE_VISIT: "v1/admin/employee_v3/save-visit",
      UPDATE_VISIT: "v1/admin/employee_v3/update-visit",
      LIST_VISIT: "v1/admin/employee_v3/list-visit",
      DELETE_VISIT: "v1/admin/employee_v3/delete-visit",

      SAVE_SUSPENSION: "v1/admin/employee_v3/save-memo",
      UPDATE_SUSPENSION: "v1/admin/employee_v3/update-memo",
      LIST_SUSPENSION: "v1/admin/employee_v3/list-memo",
      DELETE_SUSPENSION: "v1/admin/employee_v3/delete-memo",

      SAVE_SECTION_TRANSFER: "v1/admin/employee_v3/save-section-transfer",
      UPDATE_SECTION_TRANSFER: "v1/admin/employee_v3/update-section-transfer",
      LIST_SECTION_TRANSFER: "v1/admin/employee_v3/list-section-transfer",
      DELETE_SECTION_TRANSFER: "v1/admin/employee_v3/delete-section-transfer",

      BULK_SEARCH_ESTBLISHMENT:
        "v1/admin/employee_v3/bulk-search-establishment",

      DASHBOARD_STAFF_COUNT: "v1/admin/employee_v3/list-staff-count",
      DASHBOARD_STAFF_TABLE: "v1/admin/employee_v3/list-staff-table",
    },

    SAVE_GRADE: "v1/admin/hrmgt/grade/save_grade",
    LIST_GRADES: "v1/admin/hrmgt/grade/list_gardes",
    REMOVE_GRADE: "v1/admin/hrmgt/grade/remove_grade",
    UPDATE_GRADE: "v1/admin/hrmgt/grade/update_grade",
    SAVE_BREAKUP_HEAD: "v1/admin/payroll/break/save_breakuphead",
    LIST_BREAKUP_HEADS: "v1/admin/payroll/break/list_breakuphead",
    REMOVE_BREAKUP_HEAD: "v1/admin/payroll/break/remove_breakuphead",
    UPDATE_BREAKUP_HEAD: "v1/admin/payroll/break/update_breakuphead",

    EMPLOYEE_NEW_CODE: "v1/admin/hrmgt/employee/employee_code",
    SAVE_EMPLOYEE: "v1/admin/hrmgt/employee/save_data",
    LIST_EMPLOYEES: "v1/admin/hrmgt/employee/list_data",
    UPDATE_EMPLOYEES_INCREMENT_DATES:
      "v1/admin/hrmgt/employee/update_increment_dates",

    REMOVE_EMPLOYEE: "v1/admin/hrmgt/employee/remove_data",
    CHANGE_EMPLOYEE_STATUS: "v1/admin/hrmgt/employee/status_chagne",
    UPDATE_EMPLOYEE: "v1/admin/hrmgt/employee/update_data",
    UPDATE_PERSONAL_INFO: "v1/admin/hrmgt/employee/update_personal_info",
    UPDATE_EMPLOYEE_SALARY: "v1/admin/hrmgt/employee/update_salary",
    EMPLOYEE_BY_CODE: "v1/admin/hrmgt/employee/employee_by_code",

    SAVE_BIOMETRIC_DEVICE: "v1/admin/hrmgt/device/save_data",
    LIST_BIOMETRIC_DEVICES: "v1/admin/hrmgt/device/list_data",
    REMOVE_BIOMETRIC_DEVICE: "v1/admin/hrmgt/device/remove_data",
    UPDATE_BIOMETRIC_DEVICE: "v1/admin/hrmgt/device/update_data",

    LIST_LEAVE: "v1/admin/hrmgt/leave/list_data",
    SAVE_HOLIDAY: "v1/admin/hrmgt/holiday/save_data",
    LIST_HOLIDAYS: "v1/admin/hrmgt/holiday/list_data",
    REMOVE_HOLIDAY: "v1/admin/hrmgt/holiday/remove_data",
    SAVE_SUNDAYS: "v1/admin/hrmgt/holiday/save_sundays",

    SAVE_HOSTEL_HOLIDAY: "v1/admin/hrmgt/holiday/save_hostel_data",
    LIST_HOSTEL_HOLIDAYS: "v1/admin/hrmgt/holiday/list_hostel_data",
    REMOVE_HOSTEL_HOLIDAY: "v1/admin/hrmgt/holiday/remove_hostel_data",
    SAVE_HOSTEL_SUNDAYS: "v1/admin/hrmgt/holiday/save_hostel_sundays",

    ATT_MONTH_LOG: "v1/admin/hrmgt/attendance/att_month_log",
    SAVE_BRANCH: "v1/admin/hrmgt/branch/save-data",
    LIST_BRANCHES: "v1/admin/hrmgt/branch/list-data",
    REMOVE_BRANCHE: "v1/admin/hrmgt/branch/remove-data",
    UPDATE_BRANCHE: "v1/admin/hrmgt/branch/update-data",
    SAVE_DEPARTMENT: "v1/admin/hrmgt/department/save-data",
    LIST_DEPARTMENTS: "v1/admin/hrmgt/department/list-data",
    LIST_STDEPARTMENTS: "v1/admin/student_dept/list-data",
    REMOVE_DEPARTMENT: "v1/admin/hrmgt/department/remove-data",
    UPDATE_DEPARTMENT: "v1/admin/hrmgt/department/update-data",

    SAVE_TYPE: "v1/admin/hrmgt/type/save-data",
    LIST_TYPE: "v1/admin/hrmgt/type/list-data",
    REMOVE_TYPE: "v1/admin/hrmgt/type/remove-data",
    UPDATE_TYPE: "v1/admin/hrmgt/type/update-data",

    LIST_CLASSIFICATION: "v1/admin/hrmgt/type/list-classification",
    SAVE_CLASSIFICATION: "v1/admin/hrmgt/type/save-classification",
    UPDATE_CLASSIFICATION: "v1/admin/hrmgt/type/update-classification",
    REMOVE_CLASSIFICATION: "v1/admin/hrmgt/type/remove-classification",

    LIST_CATEGORY_EMPLOYEE: "v1/admin/hrmgt/type/list-employeecategories",
    SAVE_CATEGORY_EMPLOYEE: "v1/admin/hrmgt/type/save-employeecategories",
    UPDATE_CATEGORY_EMPLOYEE: "v1/admin/hrmgt/type/update-employeecategories",
    REMOVE_CATEGORY_EMPLOYEE: "v1/admin/hrmgt/type/remove-employeecategories",

    SAVE_LEAVE_TYPE: "v1/admin/hrmgt/leavetype/save-data",
    LIST_LEAVE_TYPE: "v1/admin/hrmgt/leavetype/list-data",
    REMOVE_LEAVE_TYPE: "v1/admin/hrmgt/leavetype/remove-data",
    UPDATE_LEAVE_TYPE: "v1/admin/hrmgt/leavetype/update-data",
    EMPLOYEE_LEAVE_LIST: "v1/admin/hrmgt/leavetype/get-employee-allowedleaves",

    SAVE_DESIGNATION: "v1/admin/hrmgt/designation/save-data",
    LIST_DESIGNATIONS: "v1/admin/hrmgt/designation/list-data",
    REMOVE_DESIGNATION: "v1/admin/hrmgt/designation/remove-data",
    UPDATE_DESIGNATION: "v1/admin/hrmgt/designation/update-data",

    SAVE_SHIFT: "v1/admin/hrmgt/shift/save_data",
    LIST_SHIFT: "v1/admin/hrmgt/shift/list_data",
    UPDATE_SHIFT: "v1/admin/hrmgt/shift/update_data",
    REMOVE_SHIFT: "v1/admin/hrmgt/shift/remove_data",

    LAST_DEVICEID: "v1/admin/hrmgt/attendance/last_inserted_deviceid",
    INSERT_DEVICE_DATAS: "v1/admin/hrmgt/attendance/device_monthly_data",

    SAVE_ATTENDANCE: "v1/admin/hrmgt/employee/save-data",
    LIST_ATTENDANCE: "v1/admin/hrmgt/employee/list-data",
    REMOVE_ATTENDANCE: "v1/admin/hrmgt/employee/remove-data",
    UPDATE_ATTENDANCE: "v1/admin/hrmgt/employee/update-data",
    EXPORT_EMPLOYEE_REPORT: "v1/admin/hrmgt/employee/export-employee-data",

    ATTENDANCE_REPORTS: "v1/admin/hrmgt/employee/bio-report",
    ATTENDANCE_REPORTS_COUNT: "v1/admin/hrmgt/employee/bio-report-count",
    LIST_COMMUNITY: "v1/admin/hrmgt/employee/community",

    SAVE_SECTION: "v1/admin/hrmgt/section/save-data",
    LIST_SECTION: "v1/admin/hrmgt/section/list-data",
    REMOVE_SECTION: "v1/admin/hrmgt/section/remove-data",
    UPDATE_SECTION: "v1/admin/hrmgt/section/update-data",

    SAVE_INCHARGE: "v1/admin/hrmgt/incharge/save-data",
    LIST_INCHARGE: "v1/admin/hrmgt/incharge/list-data",
    REMOVE_INCHARGE: "v1/admin/hrmgt/incharge/remove-data",
    UPDATE_INCHARGE: "v1/admin/hrmgt/incharge/update-data",
  },
  FIELD_SETTINGS: {
    NEW: "v1/field_settings/new",
    LIST: "v1/field_settings/all",
    REMOVE: "v1/field_settings/delete",
    UPDATE: "v1/field_settings/update",
  },
  SMS: {
    CHECK_BALANCE: "v1/admin/sms/check_balance",
  },
  EVENTS: {
    SAVE_REPORTS: "v1/admin/events/save-reports",
    LIST_EVENTS_REPORT: "v1/admin/events/list",
    UPDATE_REPORTS: "v1/admin/events/update",
    REMOVE_REPORTS: "v1/admin/events/delete",
    SAVE_EVENT_STAFF_PDF: "v1/admin/events/save-pdf",
    DEPARTMENT_LIST: "v1/admin/events/list-department",
    CLUB_LIST: "v1/admin/events/list-club",
  },
  CIRCULAR: {
    SAVE_COLLEGE_CIRCULAR: "v1/admin/circular/college/save-circular",
    LIST_COLLEGE_CIRCULAR: "v1/admin/circular/college/list",
    UPDATE_COLLEGE_CIRCULAR: "v1/admin/circular/college/update",
    REMOVE_COLLEGE_CIRCULAR: "v1/admin/circular/college/delete",
    SAVE_OTHER_CIRCULAR: "v1/admin/circular/other/save-circular",
    LIST_OTHER_CIRCULAR: "v1/admin/circular/other/list",
    UPDATE_OTHER_CIRCULAR: "v1/admin/circular/other/update",
    REMOVE_OTHER_CIRCULAR: "v1/admin/circular/other/delete",
  },
  FACULTY: {
    LIST_ACTIVITIES: "v1/admin/faculty/list",
    SAVE_ACTIVITIES: "v1/admin/faculty/save",
    UPDATE_ACTIVITIES: "v1/admin/faculty/update",
    REMOVE_ACTIVITIES: "v1/admin/faculty/delete",
  },
  AWARD: {
    LIST_AWARDS: "v1/admin/awards/list",
    SAVE_AWARDS: "v1/admin/awards/save",
    UPDATE_AWARDS: "v1/admin/awards/update",
    REMOVE_AWARDS: "v1/admin/awards/delete",
  },
  WEBSITE_CMS: {
    SAVE_PAGE: "v1/admin/cms/page/save",
    LIST_PAGES: "v1/admin/cms/page/list",
    DELETE_PAGE: "v1/admin/cms/page/delete",
    GET_PAGE: "v1/admin/cms/page/get",
    GET_EVENT_GALLERY: "v1/admin/cms/page/get-event-gallery",
    UPDATE_PAGE: "v1/admin/cms/page/update",
    GET_EVENT_CATEGORY: "v1/admin/cms/page/get-event-category",

    SAVE_GALLERY: "v1/admin/cms/page/save-gallery",
    LIST_GALLERIES: "v1/admin/cms/page/list-galleries",
    DELETE_GALLERY: "v1/admin/cms/page/delete-gallery",
    LIST_GALLERY: "v1/admin/cms/page/list-gallery",
    UPDATE_GALLERY: "v1/admin/cms/page/update-gallery",

    HOME_PAGE_LIST: "v1/admin/cms/page/home-page-list",
    CHANGE_HOME_STATUS: "v1/admin/cms/page/change-home-status",
    UPDATE_COMPONENT_FILTER: "v1/admin/cms/page/update-component-filter",

    LIST_CATEGORY_NAMES: "v1/admin/cms/page/list-categories",

    FILES_LIST_DIRECTORY: "v1/admin/files/list-directory-v2",
    FILES_UPLOAD: "v1/admin/files/upload-files-v2",
    REMOVE_FILE: "v1/admin/files/remove-v2",
    CREATE_FOLDER: "v1/admin/files/create-folder-v2",

    UPDATE_MENU: "v1/admin/website-cms/update-menu",
    UPDATE_LINK: "v1/admin/website-cms/update-link",
  },
  DEPT_CMS: {
    SAVE_SLIDER: "v1/admin/deptcms/slider/save",
    LIST_SLIDERS: "v1/admin/deptcms/slider/list",
    DELETE_SLIDER: "v1/admin/deptcms/slider/delete",

    SAVE_PAGE: "v1/admin/deptcms/page/save",
    GET_PAGE: "v1/admin/deptcms/page/get",

    SAVE_FILE: "v1/admin/deptcms/file/save",
    LIST_FILES: "v1/admin/deptcms/file/list",
    DELETE_FILE: "v1/admin/deptcms/file/remove",
    UPDATE_FILE: "v1/admin/deptcms/file/update",
  },
  NAAC: {
    SAVE_CRITERIA: "v1/admin/naac/save-criteria",
    LIST_CRITERIA: "v1/admin/naac/list-criteria",
    REMOVE_CRITERIA: "v1/admin/naac/remove-criteria",
    UPDATE_CRITERIA: "v1/admin/naac/update-criteria",

    SAVE_CRITERIA_GROUP: "v1/admin/naac/save-criteria-group",
    LIST_CRITERIA_GROUP: "v1/admin/naac/list-criteria-group",
    REMOVE_CRITERIA_GROUP: "v1/admin/naac/remove-criteria-group",
    UPDATE_CRITERIA_GROUP: "v1/admin/naac/update-criteria-group",

    SAVE_REPORT: "v1/admin/naac/save-report",
    LIST_REPORTS: "v1/admin/naac/list-reports",
    REMOVE_REPORT: "v1/admin/naac/remove-report",
    GET_REPORT: "v1/admin/naac/get-report",
    UPDATE_REPORT: "v1/admin/naac/update-report",
  },
  UTILITIES: {
    LIST_STUDENT_CERTIFICATES: "v1/admin/util/stucert/list-all",
    GET_STUDENT_CERTIFICATE: "v1/admin/util/stucert/get",
    UPDATE_STUDENT_CERTIFICATE: "v1/admin/util/stucert/update",
    CREATE_STUDENT_CERTIFICATE: "v1/admin/util/stucert/save",

    LIST_EMPLOYEE_CERTIFICATES: "v1/admin/util/empcert/list-all",
    GET_EMPLOYEE_CERTIFICATE: "v1/admin/util/empcert/get",
    UPDATE_EMPLOYEE_CERTIFICATE: "v1/admin/util/empcert/update",
    CREATE_EMPLOYEE_CERTIFICATE: "v1/admin/util/empcert/save",

    LIST_STUDENT_TEMPLATE: "v1/admin/util/template/list-all",
    GET_STUDENT_TEMPLATE: "v1/admin/util/template/get",
    UPDATE_STUDENT_TEMPLATE: "v1/admin/util/template/update",
    CREATE_STUDENT_TEMPLATE: "v1/admin/util/template/save",

    SAVE_DAY_ORDER: "v1/admin/util/attdayorder/save",
    LIST_DAY_ORDERS: "v1/admin/util/attdayorder/list",
    DELETE_DAY_ORDER: "v1/admin/util/attdayorder/delete",
    UPDATE_DAY_ORDER: "v1/admin/util/attdayorder/update",
    DAY_ORDER_BY_BATCH: "v1/admin/util/attdayorder/by-batch",
    ACADEMIC_CALENDAR: "v1/admin/util/attdayorder/academic-calendar",

    SAVE_ATTENDANCE_PERCENTAGE: "v1/admin/util/attpercentage/save",
    LIST_ATTENDANCE_PERCENTAGES: "v1/admin/util/attpercentage/list",
    DELETE_ATTENDANCE_PERCENTAGE: "v1/admin/util/attpercentage/delete",
    UPDATE_ATTENDANCE_PERCENTAGE: "v1/admin/util/attpercentage/update",

    SAVE_SCHOLARSHIPS: "v1/admin/util/scholarship/save",
    LIST_SCHOLARSHIPS: "v1/admin/util/scholarship/list-all",
    DELETE_SCHOLARSHIPS: "v1/admin/util/scholarship/delete",
    UPDATE_SCHOLARSHIPS: "v1/admin/util/scholarship/update",

    SAVE_STUDENT_FACILITY: "v1/admin/util/studentfacility/save",
    LIST_STUDENT_FACILITY: "v1/admin/util/studentfacility/list-all",
    DELETE_STUDENT_FACILITY: "v1/admin/util/studentfacility/delete",
    UPDATE_STUDENT_FACILITY: "v1/admin/util/studentfacility/update",

    SAVE_PEDOGOGY_METHOD: "v1/admin/util/pedogogy/save",
    LIST_PEDOGOGY_METHOD: "v1/admin/util/pedogogy/list-all",
    DELETE_PEDOGOGY_METHOD: "v1/admin/util/pedogogy/delete",
    UPDATE_PEDOGOGY_METHOD: "v1/admin/util/pedogogy/update",

    SAVE_HOSTEL: "v1/admin/util/hostel/save",
    LIST_HOSTEL: "v1/admin/util/hostel/list-all",
    DELETE_HOSTEL: "v1/admin/util/hostel/delete",
    UPDATE_HOSTEL: "v1/admin/util/hostel/update",

    SAVE_BUILDING_BLOCKS: "v1/admin/util/buildingblocks/save",
    LIST_BUILDING_BLOCKS: "v1/admin/util/buildingblocks/list",
    DELETE_BUILDING_BLOCKS: "v1/admin/util/buildingblocks/delete",
    UPDATE_BUILDING_BLOCKS: "v1/admin/util/buildingblocks/update",

    SAVE_BUILDING_ROOMS: "v1/admin/util/buildingrooms/save",
    LIST_BUILDING_ROOMS: "v1/admin/util/buildingrooms/list",
    DELETE_BUILDING_ROOMS: "v1/admin/util/buildingrooms/delete",
    UPDATE_BUILDING_ROOMS: "v1/admin/util/buildingrooms/update",

    SAVE_BUILDING_TYPES: "v1/admin/util/buildingtypes/save",
    LIST_BUILDING_TYPES: "v1/admin/util/buildingtypes/list",
    DELETE_BUILDING_TYPES: "v1/admin/util/buildingtypes/delete",
    UPDATE_BUILDING_TYPES: "v1/admin/util/buildingtypes/update",

    SAVE_STUDENT_FEE_TYPES: "v1/admin/settings/feetypes/save",
    LIST_STUDENT_FEE_TYPES: "v1/admin/settings/feetypes/list",
    DELETE_STUDENT_FEE_TYPES: "v1/admin/settings/feetypes/delete",
    UPDATE_STUDENT_FEE_TYPES: "v1/admin/settings/feetypes/update",

    SAVE_ATTENDANCE_TIME_SLOT: "v1/admin/util/atttimeslot/save",
    LIST_ATTENDANCE_TIME_SLOT: "v1/admin/util/atttimeslot/list",
    DELETE_ATTENDANCE_TIME_SLOT: "v1/admin/util/atttimeslot/delete",
    UPDATE_ATTENDANCE_TIME_SLOT: "v1/admin/util/atttimeslot/update",

    SAVE_CATEGORY_HOSTEL: "v1/admin/util/hostel/save-category",
    LIST_CATEGORY_HOSTEL: "v1/admin/util/hostel/list-category",
    DELETE_CATEGORY_HOSTEL: "v1/admin/util/hostel/delete-category",
    UPDATE_CATEGORY_HOSTEL: "v1/admin/util/hostel/update-category",

    SAVE_STAFF_HOSTEL: "v1/admin/util/hostel/save-staff",
    LIST_STAFF_HOSTEL: "v1/admin/util/hostel/list-staff",
    DELETE_STAFF_HOSTEL: "v1/admin/util/hostel/delete-staff",
    UPDATE_STAFF_HOSTEL: "v1/admin/util/hostel/update-staff",
  },
  MOB: {
    CHECK_USERNAME: "mob/v1/login",
    VERIFY_LOGIN_OTP: "mob/v1/verify_otp",
    SET_LOGIN_PASSWORD: "mob/v1/set_password",
    MY_TIME_TABLE: "mob/v1/timetable/my-time-table",
    SEARCH_STUDENT: "mob/v1/students/search",
    SAVE_STUDENT_ATTENDANCE: "mob/v1/timetable/save",
    LIST_STUDENTS_ATTENDANCE: "mob/v1/timetable/list-attendance",
    CURRENT_SEM_DATE: "mob/v1/timetable/current-sem-date",
    LIST_LONG_ABSENTEE: "mob/v1/students/long-absentee",
  },
  //service urls for exam by karan
  EXAMS: {
    //templates
    ADD_TEMPLATES_EXAM_MASTER:
      "v1/admin/exams/examtemplate/add-template-exam-master",
    UPDATE_TEMPLATES_EXAM_MASTER:
      "v1/admin/exams/examtemplate/update-template-exam-master",
    TOTAL_RECORDS_TEMPLATES_EXAM_MASTER:
      "v1/admin/exams/examtemplate/total-records-template-exam-master",
    LIST_TEMPLATES_EXAM_MASTER:
      "v1/admin/exams/examtemplate/list-template-exam-master",
    DELETE_TEMPLATES_EXAM_MASTER:
      "v1/admin/exams/examtemplate/delete-template-exam-master",

    ADD_TEMPLATE_EXAM_TYPE:
      "v1/admin/exams/examtemplate/add-template-exam-type",
    UPDATE_TEMPLATE_EXAM_TYPE:
      "v1/admin/exams/examtemplate/update-template-exam-type",
    TOTAL_RECORDS_TEMPLATE_EXAM_TYPE:
      "v1/admin/exams/examtemplate/total-records-template-exam-type",
    LIST_TEMPLATE_EXAM_TYPE:
      "v1/admin/exams/examtemplate/list-template-exam-type",
    DELETE_TEMPLATE_EXAM_TYPE:
      "v1/admin/exams/examtemplate/delete-template-exam-type",

    ADD_TEMPLATE_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examtemplate/add-template-exam-type-categorization",
    UPDATE_TEMPLATE_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examtemplate/update-template-exam-type-categorization",
    TOTAL_RECORDS_TEMPLATE_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examtemplate/total-records-template-exam-type-categorization",
    LIST_TEMPLATE_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examtemplate/list-template-exam-type-categorization",
    DELETE_TEMPLATE_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examtemplate/delete-template-exam-type-categorization",

    ADD_TEMPLATE_CONSOLIDATION:
      "v1/admin/exams/examtemplate/add-template-consolidation",
    UPDATE_TEMPLATE_CONSOLIDATION:
      "v1/admin/exams/examtemplate/update-template-consolidation",
    TOTAL_RECORDS_TEMPLATE_CONSOLIDATION:
      "v1/admin/exams/examtemplate/total-records-template-consolidation",
    LIST_TEMPLATE_CONSOLIDATION:
      "v1/admin/exams/examtemplate/list-template-consolidation",
    DELETE_TEMPLATE_CONSOLIDATION:
      "v1/admin/exams/examtemplate/delete-template-consolidation",

    ADD_TEMPLATE_CONSOLIDATION_PROP:
      "v1/admin/exams/examtemplate/add-template-consolidation-prop",
    UPDATE_TEMPLATE_CONSOLIDATION_PROP:
      "v1/admin/exams/examtemplate/update-template-consolidation-prop",
    TOTAL_RECORDS_TEMPLATE_CONSOLIDATION_PROP:
      "v1/admin/exams/examtemplate/total-records-template-consolidation-prop",
    LIST_TEMPLATE_CONSOLIDATION_PROP:
      "v1/admin/exams/examtemplate/list-template-consolidation-prop",
    DELETE_TEMPLATE_CONSOLIDATION_PROP:
      "v1/admin/exams/examtemplate/delete-template-consolidation-prop",

    LIST_ALL_TEMPLATE_EXAM_TYPES_AND_CATEGORIZATION:
      "v1/admin/exams/examtemplate/list-all-template-exam-types-and-categorization",
    LIST_ALL_TEMPLATE_CONSOLIDATIONS_AND_CONSOLIDATION_PROPS:
      "v1/admin/exams/examtemplate/list-all-template-consolidations-and-consolidation-props",

    //config
    ADD_EXAM: "v1/admin/exams/examconfig/add-exam",
    UPDATE_EXAM: "v1/admin/exams/examconfig/update-exam",
    TOTAL_RECORDS_EXAM: "v1/admin/exams/examconfig/total-records-exam",
    LIST_EXAM: "v1/admin/exams/examconfig/list-exam",
    DELETE_EXAM: "v1/admin/exams/examconfig/delete-exam",

    COUNT_GLOBAL_CONSOLIDATION:
      "v1/admin/exams/foilcard/count-global-consolidation",
    GET_GLOBAL_CONSOLIDATION:
      "v1/admin/exams/foilcard/get-global-consolidation",
    GET_GLOBAL_CONSOLIDATION_PROPS:
      "v1/admin/exams/foilcard/global-consolidation-props",
    LIST_FOIL_CLASS: "v1/admin/exams/foilcard/list-foil-class",
    LIST_FOIL_SUBJECT_TIME_TABLE: "v1/admin/exams/foilcard/list-foil-subject",
    LIST_FOIL_DATA: "v1/admin/exams/foilcard/list-foil-data",
    GET_FOIL_TEMPLATE: "v1/admin/exams/foilcard/get-foil-template",
    GET_CONSOLIDATION_DATA: "v1/admin/exams/foilcard/get-consolidation-data",
    LIST_FOIL_MARKS: "v1/admin/exams/foilcard/list-foil-marks",
    COUNT_FOIL_DATA: "v1/admin/exams/foilcard/count-foil-data",
    LIST_BULK_FOIL_DATA: "v1/admin/exams/foilcard/list-bulk-foil-data",

    ADD_EXAM_TYPE: "v1/admin/exams/examconfig/add-exam-type",
    UPDATE_EXAM_TYPE: "v1/admin/exams/examconfig/update-exam-type",
    TOTAL_RECORDS_EXAM_TYPE:
      "v1/admin/exams/examconfig/total-records-exam-type",
    LIST_EXAM_TYPE: "v1/admin/exams/examconfig/list-exam-type",
    DELETE_EXAM_TYPE: "v1/admin/exams/examconfig/delete-exam-type",

    ADD_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examconfig/add-exam-type-categorization",
    UPDATE_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examconfig/update-exam-type-categorization",
    TOTAL_RECORDS_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examconfig/total-records-exam-type-categorization",
    LIST_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examconfig/list-exam-type-categorization",
    DELETE_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examconfig/delete-exam-type-categorization",

    ADD_EXAM_CLASS: "v1/admin/exams/examconfig/add-exam-class",
    UPDATE_EXAM_CLASS: "v1/admin/exams/examconfig/update-exam-class",
    TOTAL_RECORDS_EXAM_CLASS:
      "v1/admin/exams/examconfig/total-records-exam-class",
    LIST_EXAM_CLASS: "v1/admin/exams/examconfig/list-exam-class",
    DELETE_EXAM_CLASS: "v1/admin/exams/examconfig/delete-exam-class",

    ADD_EXAM_TIME_TABLE: "v1/admin/exams/examconfig/add-exam-time-table",
    UPDATE_EXAM_TIME_TABLE: "v1/admin/exams/examconfig/update-exam-time-table",
    TOTAL_RECORDS_EXAM_TIME_TABLE:
      "v1/admin/exams/examconfig/total-records-exam-time-table",
    LIST_EXAM_TIME_TABLE: "v1/admin/exams/examconfig/list-exam-time-table",
    DELETE_EXAM_TIME_TABLE: "v1/admin/exams/examconfig/delete-exam-time-table",

    ADD_COS_EXAM_CLASS_SUBJECT:
      "v1/admin/exams/examconfig/add-cos-exam-class-subject",
    UPDATE_COS_EXAM_CLASS_SUBJECT:
      "v1/admin/exams/examconfig/update-cos-exam-class-subject",
    TOTAL_RECORDS_COS_EXAM_CLASS_SUBJECT:
      "v1/admin/exams/examconfig/total-records-cos-exam-class-subject",
    LIST_COS_EXAM_CLASS_SUBJECT:
      "v1/admin/exams/examconfig/list-cos-exam-class-subject",
    DELETE_COS_EXAM_CLASS_SUBJECT:
      "v1/admin/exams/examconfig/delete-cos-exam-class-subject",

    ADD_EXAM_CONSOLIDATION: "v1/admin/exams/examconfig/add-exam-consolidation",
    UPDATE_EXAM_CONSOLIDATION:
      "v1/admin/exams/examconfig/update-exam-consolidation",
    TOTAL_RECORDS_EXAM_CONSOLIDATION:
      "v1/admin/exams/examconfig/total-records-exam-consolidation",
    LIST_EXAM_CONSOLIDATION:
      "v1/admin/exams/examconfig/list-exam-consolidation",
    DELETE_EXAM_CONSOLIDATION:
      "v1/admin/exams/examconfig/delete-exam-consolidation",

    ADD_EXAM_CONSOLIDATION_PROPERTIES:
      "v1/admin/exams/examconfig/add-exam-consolidation-properties",
    UPDATE_EXAM_CONSOLIDATION_PROPERTIES:
      "v1/admin/exams/examconfig/update-exam-consolidation-properties",
    TOTAL_RECORDS_EXAM_CONSOLIDATION_PROPERTIES:
      "v1/admin/exams/examconfig/total-records-exam-consolidation-properties",
    LIST_EXAM_CONSOLIDATION_PROPERTIES:
      "v1/admin/exams/examconfig/list-exam-consolidation-properties",
    DELETE_EXAM_CONSOLIDATION_PROPERTIES:
      "v1/admin/exams/examconfig/delete-exam-consolidation-properties",

    ADD_EXAM_GRADE: "v1/admin/exams/examconfig/add-exam-grade",
    UPDATE_EXAM_GRADE: "v1/admin/exams/examconfig/update-exam-grade",
    TOTAL_RECORDS_EXAM_GRADE:
      "v1/admin/exams/examconfig/total-records-exam-grade",
    LIST_EXAM_GRADE: "v1/admin/exams/examconfig/list-exam-grade",
    DELETE_EXAM_GRADE: "v1/admin/exams/examconfig/delete-exam-grade",

    ELECTIVE_DETECTION_LIST:
      "v1/admin/exams/examconfig/elective-detection-list",

    ADD_COK_PATTERN: "v1/admin/exams/examconfig/add-cok-pattern",
    UPDATE_COK_PATTERN: "v1/admin/exams/examconfig/update-cok-pattern",
    LIST_COK_PATTERN: "v1/admin/exams/examconfig/list-cok-pattern",
    DELETE_COK_PATTERN: "v1/admin/exams/examconfig/delete-cok-pattern",
    ADD_COK_SCALING: "v1/admin/exams/examconfig/add-cok-scaling",
    UPDATE_COK_SCALING: "v1/admin/exams/examconfig/update-cok-scaling",
    LIST_COK_SCALING: "v1/admin/exams/examconfig/list-cok-scaling",
    DELETE_COK_SCALING: "v1/admin/exams/examconfig/delete-cok-scaling",

    ADD_EXAM_GRADE_SCALE: "v1/admin/exams/examconfig/add-exam-grade-scale",
    UPDATE_EXAM_GRADE_SCALE:
      "v1/admin/exams/examconfig/update-exam-grade-scale",
    TOTAL_RECORDS_EXAM_GRADE_SCALE:
      "v1/admin/exams/examconfig/total-records-exam-grade-scale",
    LIST_EXAM_GRADE_SCALE: "v1/admin/exams/examconfig/list-exam-grade-scale",
    DELETE_EXAM_GRADE_SCALE:
      "v1/admin/exams/examconfig/delete-exam-grade-scale",

    ADD_MARK_ENTRY_STAFF: "v1/admin/exams/examconfig/add-mark-entry-staff",
    UPDATE_MARK_ENTRY_STAFF:
      "v1/admin/exams/examconfig/update-mark-entry-staff",
    TOTAL_RECORDS_MARK_ENTRY_STAFF:
      "v1/admin/exams/examconfig/total-records-mark-entry-staff",
    LIST_MARK_ENTRY_STAFF: "v1/admin/exams/examconfig/list-mark-entry-staff",
    TOTAL_FILTER_RECORDS_MARK_ENTRY_STAFF:
      "v1/admin/exams/examconfig/total-filter-records-mark-entry-staff",
    DELETE_MARK_ENTRY_STAFF:
      "v1/admin/exams/examconfig/delete-mark-entry-staff",
    LIST_CLASS_WISE_STUDENTS_MARKS:
      "v1/admin/exams/examconfig/list-class-wise-students-marks",

    ADD_EXAM_SCHEDULE: "v1/admin/exams/examconfig/add-exam-schedule",
    UPDATE_EXAM_SCHEDULE: "v1/admin/exams/examconfig/update-exam-schedule",
    TOTAL_RECORDS_EXAM_SCHEDULE:
      "v1/admin/exams/examconfig/total-records-exam-schedule",
    LIST_EXAM_SCHEDULE: "v1/admin/exams/examconfig/list-exam-schedule",
    DELETE_EXAM_SCHEDULE: "v1/admin/exams/examconfig/delete-exam-schedule",

    GENERATE_EXAM_DEFAULTS: "v1/admin/exams/examconfig/generate-exam-defaults",
    ALL_SUBJECTS_IN_CLASS: "v1/admin/exams/examconfig/all-subjects-in-class",
    GENERATE_EXAM_CLASS_TIME_TABLE:
      "v1/admin/exams/examconfig/generate-exam-class-time-table",
    ALL_STUDENTS_IN_EXAM_SUBJECT:
      "v1/admin/exams/examconfig/all-students-in-exam-subject",
    ALL_ACADEMIC_DEPARTMENTS:
      "v1/admin/exams/examconfig/all-academic-departments",
    GENERATE_EXAM_SUBJECT_EXAM_TYPE_DEFAULT:
      "v1/admin/exams/examconfig/generate-exam-subject-exam-type-default",
    GENERATE_EXAM_SUBJECT_EXAM_TYPE_CO_DEFAULT:
      "v1/admin/exams/examconfig/generate-exam-subject-exam-type-co-default",
    ADD_ALL_COS_EXAM_CLASS_SUBJECT:
      "v1/admin/exams/examconfig/add-all-cos-exam-class-subject",
    COPY_CO_IF_MATCHED_RECENT:
      "v1/admin/exams/examconfig/copy-co-if-matched-recent",
    DELETE_SUBJECT_MARKS_AND_CONFIGURATIONS:
      "v1/admin/exams/examconfig/delete-subject-marks-and-configurations",

    //marks
    ADD_QUESTION_MARKS: "v1/admin/exams/marks/add-question-marks",
    UPDATE_QUESTION_MARKS: "v1/admin/exams/marks/update-question-marks",
    TOTAL_RECORDS_QUESTION_MARKS:
      "v1/admin/exams/marks/total-records-question-marks",
    LIST_QUESTION_MARKS: "v1/admin/exams/marks/list-question-marks",
    DELETE_QUESTION_MARKS: "v1/admin/exams/marks/delete-question-marks",

    ADD_TOTAL_MARKS: "v1/admin/exams/marks/add-total-mark",
    UPDATE_TOTAL_MARKS: "v1/admin/exams/marks/update-total-mark",
    TOTAL_RECORDS_TOTAL_MARKS: "v1/admin/exams/marks/total-records-total-mark",
    LIST_TOTAL_MARKS: "v1/admin/exams/marks/list-total-mark",
    DELETE_TOTAL_MARKS: "v1/admin/exams/marks/delete-total-mark",

    ADD_UPDATE_ALL_STUDENT_MARKS:
      "v1/admin/exams/marks/add-update-all-student-marks",
    ADD_UPDATE_ALL_STUDENT_TOTAL_MARKS:
      "v1/admin/exams/marks/add-update-all-student-total-marks",
    LIST_MARKS_ENTRY_STATUS: "v1/admin/exams/marks/list-marks-entry-status",

    LIST_EXAM_SCHEDULE_AND_LOCK:
      "v1/admin/exams/examconfig/list-exam-schedule-and-lock",
    TOTAL_RECORDS_EXAM_TIME_TABLE_AND_LOCK:
      "v1/admin/exams/examconfig/total-records-exam-time-table-and-lock",
    // FILTER_LIST_EXAM_TIME_TABLE_AND_LOCK:
    //   "v1/admin/exams/examconfig/filter-list-exam-time-table-and-lock",
    ADD_UPDATE_EXAM_SCHEDULE:
      "v1/admin/exams/examconfig/add-update-exam-schedule",
    LIST_QUESTION_MARKS_ONLY: "v1/admin/exams/marks/list-question-marks-only",
    SUBMIT_CONSOLIDATION_MARKS:
      "v1/admin/exams/marks/submit-consolidation-marks",
    TOTAL_RECORDS_EXAM_STUDENT_ATTAINMENT:
      "v1/admin/exams/marks/total-records-exam-student-attainment",
    LIST_EXAM_STUDENT_ATTAINMENT:
      "v1/admin/exams/marks/list-exam-student-attainment",
    LIST_CO_K_VALUES: "v1/admin/exams/marks/co-kvalue-list",
    ADD_AUTO_MARKS: "v1/admin/exams/marks/add-auto-marks",
    TOTAL_ALL_PASS_COUNT: "v1/admin/exams/marks/total-all-pass-count",
    ALL_PASS_DATA: "v1/admin/exams/marks/all-pass-data",

    //global-consolidation
    ADD_GLOBAL_CONSOLIDATION: "v1/admin/global-consolidation/save",
    UPDATE_GLOBAL_CONSOLIDATION: "v1/admin/global-consolidation/update",
    LIST_GLOBAL_CONSOLIDATION: "v1/admin/global-consolidation/list",
    LIST_ONLY_GLOBAL_CONSOLIDATION: "v1/admin/global-consolidation/list-only",
    DELETE_GLOBAL_CONSOLIDATION: "v1/admin/global-consolidation/delete",

    ADD_GLOBAL_CONSOLIDATION_PROPS: "v1/admin/global-consolidation/props/save",
    UPDATE_GLOBAL_CONSOLIDATION_PROPS:
      "v1/admin/global-consolidation/props/update",
    LIST_GLOBAL_CONSOLIDATION_PROPS: "v1/admin/global-consolidation/props/list",
    CONSOLIDATION_LIST_GLOBAL_CONSOLIDATION_PROPS:
      "v1/admin/global-consolidation/props/consolidation-list",
    DELETE_GLOBAL_CONSOLIDATION_PROPS:
      "v1/admin/global-consolidation/props/delete",

    EXAM_TIME_TABLE_SUBJECTS:
      "v1/admin/exams/examconfig/exam-time-table-subjects",
    EXAM_TIME_TABLE_CLASSESS:
      "v1/admin/exams/examconfig/exam-time-table-classes",
    LIST_CO_PO_MAPPING: "v1/admin/exams/examconfig/exam-co-po-mapping",
    COPY_COPO_IF_MATCHED_RECENT:
      "v1/admin/exams/examconfig/copy-copo-if-matched-recent",
    /* DELETE_CO_PO_MAPPING: "v1/admin/exams/examconfig/exam-co-po-mapping/cancel", */
    SAVE_CO_PO_MAPPING: "v1/admin/exams/examconfig/save-co-po-mapping",

    //added by soundariya for mark data
    GET_STUDENT_MARK_INFO: "v1/admin/exams/marks/get-single-student-markinfo",

    LIST_SUBJECT_CURRICULUM:
      "v1/admin/exams/examconfig/list-subject-curriculum",
    LIST_REPORT_COLUMNS: "v1/admin/exams/examconfig/list-report-columns",
    UPDATE_SUBJECT_CURRICULUM:
      "v1/admin/exams/examconfig/update-course-subject-curriculum",
    GENERATE_REG_NO_STUDENT_LIST:
      "v1/admin/exams/examconfig/generate-reg-no-student-list",
    GENERATE_SERIAL_NUMBER: "v1/admin/exams/examconfig/generate-serial-number",
    SAVE_UPADTE_GENERATE_SERIAL_NUMBER:
      "v1/admin/exams/examconfig/update-generate-serial-no",
    ARREAR_EXAM_STUDENT_LIST:
      "v1/admin/exams/examconfig/exam-arrear-student-list",
    ADD_ARREAR_EXAMS: "v1/admin/exams/examconfig/add-override-exams",
    LIST_ARREAR_EXAMS: "v1/admin/exams/examconfig/list-override-exams",
    OVERRIDE_EXAM_CLASS_LIST:
      "v1/admin/exams/examconfig/override-exam-class-list",
    COURSE_LIST_FOR_GENERATE_REGISTER_NO:
      "v1/admin/exams/examconfig/course-list-for-generate-register-no",
    EXAM_MONTH_LIST: "v1/admin/exams/examconfig/exam-month-list",
    TOTAL_ARREAR_REPORT_LIST:
      "v1/admin/exams/arrearreport/total-arrear-exam-students-list",
    ARREAR_REPORT_LIST: "v1/admin/exams/arrearreport/arrear-exam-students-list",
    UPDATE_OVERRIDE_NOMINAL:
      "v1/admin/exams/arrearreport/update-override-nominal",
    TOTAL_OVERALL_ARREAR_EXAM_STUDENTS_LIST:
      "v1/admin/exams/arrearreport/total-overall-arrear-exam-students-list",
    OVERALL_ARREAR_EXAM_STUDENTS_LIST:
      "v1/admin/exams/arrearreport/overall-arrear-exam-students-list",
    TOTAL_SUBMIT_STUDENTS: "v1/admin/exams/marks/total-records-submit-students",
    LIST_SUBMIT_STUDENTS: "v1/admin/exams/marks/list-submit-students",
    GET_STUDENT_MARKS: "v1/admin/exams/marks/get-students-marks",
    ADD_FOIL_STUDENT_MARKS: "v1/admin/exams/marks/add-foil-marks",
    GET_FOIL_STUDENTS: "v1/admin/exams/marks/get-foil-students",
    UPDATE_FOIL_GRACE_MARKS: "v1/admin/exams/marks/update-foil-grace-marks",
    EXAM_TYPE_NAME_ONLY: "v1/admin/exams/examconfig/exam-type-name-only",

    TOTAL_AUTO_STUDENTS: "v1/admin/exams/marks/total-auto-students",
    AUTO_STUDENTS_LIST: "v1/admin/exams/marks/auto-students-list",
    STUDENT_ATTENDANCE_DATA: "v1/admin/exams/marks/student-attendance-data",

    EMPLOYEE_OFFERING_PROGRAMMES: "v1/admin/exams/marks/offering-programmes",
    INTERNAL_APPROVE_SUBJECT_LIST:
      "v1/admin/exams/marks/internal-approve-subject-list",
    INTERNAL_APPROVE_MARKS_DATA:
      "v1/admin/exams/marks/internal-approve-marks-data",
    INTERNAL_APPROVE_UPDATE: "v1/admin/exams/marks/internal-approve-update",
    STAFF_SUBMIT_MARKS: "v1/admin/exams/marks/submit-staff-marks",

    TOTAL_INTERNAL_TIME_TABLE_SUBJECT:
      "v1/admin/exams/internaltimetable/total-exam-time-table-subject",
    INTERNAL_TIME_TABLE_SUBJECT_LIST:
      "v1/admin/exams/internaltimetable/exam-time-table-subject-list",
    INTERNAL_UPDATE_TIME_TABLE_SUBJECTS:
      "v1/admin/exams/internaltimetable/update-exam-time-table-subjects",
  },

  MASKED_NUMBER: {
    GENERATE_MASKED_NO_STUDENTS_LIST:
      "v1/admin/exams/maskednumber/generate-masked-no-student-list",
    GENERATE_MASKED_NO: "v1/admin/exams/maskednumber/generate-masked-number",
    UPDATE_GENERATE_MASKED_NO:
      "v1/admin/exams/maskednumber/update-generate-masked-no",
    SERIAL_LIST: "v1/admin/exams/maskednumber/serial-list",
  },

  EXAM_CREDITS: {
    EXAM_CREDITS_STUDENT_LIST:
      "v1/admin/exams/examcredits/exam-credits-student-list",
    UPDATE_EXAM_CREDITS: "v1/admin/exams/examcredits/update-exam-credits",
    EXAM_CREDITS_TYPE_LIST: "v1/admin/exams/examcredits/exam-credits-type-list",
    ADD_EXAM_CREDITS_TYPE: "v1/admin/exams/examcredits/add-exam-credits-types",
    UPDATE_EXAM_CREDITS_TYPE:
      "v1/admin/exams/examcredits/edit-exam-credits-types",
    DELETE_EXAM_CREDITS_TYPE:
      "v1/admin/exams/examcredits/delete-exam-credits-types",
  },

  EXTERNAL: {
    ADD_EXTERNAL_PERMISSIONS: "v1/admin/exams/external/add-externalpermissions",
    LIST_EXTERNAL_PERMISSIONS:
      "v1/admin/exams/external/list-externalpermissions",
    DELETE_EXTERNAL_PERMISSIONS:
      "v1/admin/exams/external/delete-externalpermissions",
    UPDATE_EXTERNAL_PERMISSIONS:
      "v1/admin/exams/external/update-externalpermissions",
    TOTAL_RECORDS_EXTERNAL_PERMISSIONS:
      "v1/admin/exams/external/total-records-externalpermissions",

    ADD_EXTERNAL_EXAMINERS: "v1/admin/exams/external/add-examiners",
    LIST_EXTERNAL_EXAMINERS: "v1/admin/exams/external/list-examiners",
    DELETE_EXTERNAL_EXAMINERS: "v1/admin/exams/external/delete-examiners",
    UPDATE_EXTERNAL_EXAMINERS: "v1/admin/exams/external/update-examiners",
    TOTAL_RECORDS_EXTERNAL_EXAMINERS:
      "v1/admin/exams/external/total-records-examiners",
  },

  EXAM_RESULTS: {
    ADD_EXAM_RESULTS: "v1/admin/exams/examresult/add-results",
    LIST_EXAM_RESULTS: "v1/admin/exams/examresult/list-results",
    DELETE_EXAM_RESULTS: "v1/admin/exams/examresult/delete-results",
    UPDATE_EXAM_RESULTS: "v1/admin/exams/examresult/update-results",
    TOTAL_EXAM_RESULTS: "v1/admin/exams/examresult/total-records-results",

    ADD_EXAM_RESULTS_METHOD:
      "v1/admin/exams/examresult/add-resultpublishmethod",
    LIST_EXAM_RESULTS_METHOD:
      "v1/admin/exams/examresult/list-resultpublishmethod",
    DELETE_EXAM_RESULTS_METHOD:
      "v1/admin/exams/examresult/delete-resultpublishmethod",
    UPDATE_EXAM_RESULTS_METHOD:
      "v1/admin/exams/examresult/update-resultpublishmethod",
    TOTAL_EXAM_RESULTS_METHOD:
      "v1/admin/exams/examresult/total-records-resultpublishmethod",

    TOTAL_GENERATE_RESULTS_STUDENTS:
      "v1/admin/exams/results/total-generate-results-students",
    GENERATE_RESULTS_STUDENTS_LIST:
      "v1/admin/exams/results/generate-results-students-list",
    ADD_RESULT_PUBLISH_STUDENTS:
      "v1/admin/exams/results/add-resultpublish-students",

    TOTAL_REPORT_COPY_STUDENTS_LIST:
      "v1/admin/exams/results/total-report-copy-students-list",
    REPORT_COPY_STUDENTS_LIST:
      "v1/admin/exams/results/report-copy-students-list",

    RESULTS_COURSEWISE_STUDENTS_LIST:
      "v1/admin/exams/results/results-coursewise-students-list",
    RESULTS_COMMUNITY_STUDENTS_LIST:
      "v1/admin/exams/results/results-communitywise-students-list",

    COUNT_RECOMMENDATION: "v1/admin/exams/results/count-recommendation",
    RECOMMENDATION_LIST: "v1/admin/exams/results/recommendation-list",

    TOTAL_RESULTS_COURSEWISE_STUDENTS_LIST:
      "v1/admin/exams/results/total-results-coursewise-students-list",
    RESULT_TOTAL_MAPPING: "v1/admin/exams/results/result-total-mapping",
    RESULT_MARK_NON_NUMERIC_CASES:
      "v1/admin/exams/results/mark-non-numeric-cases",
  },

  MARK_SHEET: {
    MARK_SHEET_TEMPLATE: "v1/admin/exams/marksheet/mark-sheet-template",
    TOTAL_MARK_SHEET_STUDENTS_LIST:
      "v1/admin/exams/marksheet/total-mark-sheet-students-list",
    MARK_SHEET_STUDENTS_LIST:
      "v1/admin/exams/marksheet/mark-sheet-students-list",
  },

  EXAM_FEES: {
    SAVE_EXAM_FEE_CONFIG: "v1/admin/exams/examfee/add-exam-fee-config",
    TOTAL_EXAM_FEE_CONFIG:
      "v1/admin/exams/examfee/total-records-exam-fee-config",
    LIST_EXAM_FEE_CONFIG: "v1/admin/exams/examfee/list-exam-fee-config",
    UPDATE_EXAM_FEE_CONFIG: "v1/admin/exams/examfee/update-exam-fee-config",
    REMOVE_EXAM_FEE_CONFIG: "v1/admin/exams/examfee/delete-exam-fee-config",

    SAVE_EXAM_PRINT_TEMPLATE: "v1/admin/exams/examfee/add-exam-fee-config",
    TOTAL_EXAM_PRINT_TEMPLATE:
      "v1/admin/exams/examfee/total-records-exam-print-template",
    LIST_EXAM_PRINT_TEMPLATE: "v1/admin/exams/examfee/list-exam-print-template",
    UPDATE_EXAM_PRINT_TEMPLATE:
      "v1/admin/exams/examfee/update-exam-print-template",
    REMOVE_EXAM_PRINT_TEMPLATE:
      "v1/admin/exams/examfee/delete-exam-print-template",
    FEE_GENERATE_POST_DATA: "v1/admin/exams/examfee/fee-generate-post-data",
    ALREADY_IN_FEES_OR_PAYMENT_LIST:
      "v1/admin/exams/examfee/already-in-fees-or-payment-list",

    SAVE_EXAM_FEE_RULES: "v1/admin/exams/examfee/add-exam-fee-rules",
    TOTAL_EXAM_FEE_RULES: "v1/admin/exams/examfee/total-records-exam-fee-rules",
    LIST_EXAM_FEE_RULES: "v1/admin/exams/examfee/list-exam-fee-rules",
    UPDATE_EXAM_FEE_RULES: "v1/admin/exams/examfee/update-exam-fee-rules",
    REMOVE_EXAM_FEE_RULES: "v1/admin/exams/examfee/delete-exam-fee-rules",
    EXAM_FEE_STUDENTS_COUNT: "v1/admin/exams/examfee/exam-fee-student-count",
    EXAM_FEE_STUDENTS_LIST: "v1/admin/exams/examfee/exam-fee-student-list",
    GENERATE_EXAM_FEE_STUDENTS: "v1/admin/exams/examfee/generate-exam-fees",

    TOTAL_EXAM_FEE_REPORT:
      "v1/admin/exams/examfee/total-records-exam-fee-report",
    LIST_EXAM_FEE_REPORT: "v1/admin/exams/examfee/list-exam-fee-report",
    UPDATE_EXAM_FEES: "v1/admin/exams/examfee/update-exam-fees",
    TOTAL_NOMINAL_STUDENTS_LIST:
      "v1/admin/exams/examfee/total-records-nominal-report",
    NOMINAL_STUDENTS_LIST: "v1/admin/exams/examfee/list-nominal-report",

    TOTAL_EXAM_FEE_PAYMENT_LIST:
      "v1/admin/exams/examfee/total-records-exam-fee-payment-report",
    EXAM_FEE_PAYMENT_LIST:
      "v1/admin/exams/examfee/list-exam-fee-payment-report",
    TOTAL_EXAM_TIME_TABLE_SUBJECT:
      "v1/admin/exams/examtimetable/total-exam-time-table-subject",
    EXAM_TIME_TABLE_SUBJECT_LIST:
      "v1/admin/exams/examtimetable/exam-time-table-subject-list",
    UPDATE_EXAM_TIME_TABLE_SUBJECTS:
      "v1/admin/exams/examtimetable/update-exam-time-table-subjects",

    DATEWISE_EXAM_REPORT: "v1/admin/exams/examtimetable/datewise-exam-report",
    TOTAL_DATEWISE_EXAM_REPORT:
      "v1/admin/exams/examtimetable/total-datewise-exam-report",
    DATEWISE_EXAM_STUDENTS_REPORT:
      "v1/admin/exams/examtimetable/datewise-exam-students-report",
    TOTAL_DATEWISE_EXAM_STUDENTS_REPORT:
      "v1/admin/exams/examtimetable/total-datewise-exam-students-report",
    COE_HALL_TICKET:
      "v1/admin/exams/examtimetable/list-exam-hallticket-examination",
    TOTAL_COE_HALL_TICKET_STUDENTS_LIST:
      "v1/admin/exams/examtimetable/total-hallticket-student-list",
    COE_HALL_TICKET_STUDENTS_LIST:
      "v1/admin/exams/examtimetable/hallticket-student-list ",

    EXAM_HTK_STUDENTS_SUBJECT_LIST:
      "mob/stu/v1/hallticket/hallticket-students-subject-list",
    HALLTICKET_PDF_PREVIEW: "mob/stu/v1/hallticket/hallticket-pdf-preview",

    LIST_CATEGORY: "v1/admin/exams/revaluation/list-payment-category",
    SAVE_CATEGORY: "v1/admin/exams/revaluation/save-payment-category",
    UPDATE_CATEGORY: "v1/admin/exams/revaluation/update-payment-category",
    DELETE_CATEGORY: "v1/admin/exams/revaluation/delete-payment-category",

    LIST_EXAM_COURSE: "v1/admin/exams/revaluation/list-exam-courses",
    LIST_REVALUATION_DATES: "v1/admin/exams/revaluation/list-revaluation-dates",
    UPDATE_REVALUATION_DATE:
      "v1/admin/exams/revaluation/update-revaluation-dates",
  },

  //service urls for assets, visitor and letters

  ASSETS: {
    SAVE_ASSET: "v1/admin/assets/save-asset",
    LIST_ASSETS: "v1/admin/assets/list-assets",
    VIEW_ASSETS: "v1/admin/assets/view-assets",
    REMOVE_ASSET: "v1/admin/assets/remove-asset",
    UPDATE_ASSETS: "v1/admin/assets/update-assets",

    SAVE_COMPANY: "v1/admin/assets/addcompany/save-company",
    LIST_COMPANY: "v1/admin/assets/addcompany/list-company",
    UPDATE_COMPANY: "v1/admin/assets/addcompany/update-company",
    REMOVE_COMPANY: "v1/admin/assets/addcompany/remove-company",

    SAVE_DISTRIBUTION: "v1/admin/assets/distribution/save-distribution",
    LIST_DISTRIBUTION: "v1/admin/assets/distribution/list-distribution",
    UPDATE_DISTRIBUTION: "v1/admin/assets/distribution/update-distribution",
    REMOVE_DISTRIBUTION: "v1/admin/assets/distribution/remove-distribution",

    SAVE_RETURN: "v1/admin/assets/return/save-return",
    LIST_RETURN: "v1/admin/assets/return/list-return",
    GET_RETURN: "v1/admin/assets/return/get-return",

    SAVE_SERVICE_RECORD: "v1/admin/assets/record/save-service-record",
    LIST_SERVICE_RECORD: "v1/admin/assets/record/list-service-record",
    UPDATE_SERVICE_RECORD: "v1/admin/assets/record/update-service-record",

    SAVE_CATEGORY: "v1/admin/assets/category/save",
    LIST_CATEGORY: "v1/admin/assets/category/list",
    LIST_ASSEST_SPECIFICATION: "v1/admin/assets/category/list-specification",
    LIST_COUNT_CATEGORY: "v1/admin/assets/category/count-list",
    DELETE_CATEGORY: "v1/admin/assets/category/delete",
    UPDATE_CATEGORY: "v1/admin/assets/category/update",

    SAVE_ASSET_NEW: "v1/admin/assets/assetnew/save-asset-new",
    LIST_ASSET_NEW: "v1/admin/assets/assetnew/list-asset-new",
    COUNT_ASSET_NEW: "v1/admin/assets/assetnew/count-asset-new",
    UPDATE_ASSET_NEW: "v1/admin/assets/assetnew/update-asset-new",
    REMOVE_ASSET_NEW: "v1/admin/assets/assetnew/remove-asset-new",

    SAVE_ASSET_PURCHASE: "v1/admin/assets/purchase/save-asset-purchase",
    LIST_ASSET_PURCHASE: "v1/admin/assets/purchase/list-asset-purchase",
    COUNT_ASSET_PURCHASE: "v1/admin/assets/purchase/count-asset-purchase",
    UPDATE_ASSET_PURCHASE: "v1/admin/assets/purchase/update-asset-purchase",
    REMOVE_ASSET_PURCHASE: "v1/admin/assets/purchase/remove-asset-purchase",
    LIST_SUPPLIER_PURCHASE: "v1/admin/assets/purchase/list-supplier-purchase",

    SAVE_ASSET_LOCATION: "v1/admin/assets/assetlocation/save-asset-location",
    LIST_ASSET_LOCATION: "v1/admin/assets/assetlocation/list-asset-location",
    LIST_ADVANCED_LOCATION:
      "v1/admin/assets/assetlocation/advance-search-list-location",
    UPDATE_ASSET_LOCATION:
      "v1/admin/assets/assetlocation/update-asset-location",
    REMOVE_ASSET_LOCATION:
      "v1/admin/assets/assetlocation/remove-asset-location",
    LIST_LOCATION: "v1/admin/assets/assetlocation/list-location",

    COUNT_ASSET_CATEGORY: "v1/admin/assets/assetcategory/count-asset-category",
    LIST_ASSET_CATEGORY: "v1/admin/assets/assetcategory/list-asset-category",
  },
  VISITOR: {
    SAVE_VISITOR: "v1/admin/visitors/save-visitor",
    LIST_VISITORS: "v1/admin/visitors/list-visitors",
    UPDATE_VISITOR: "v1/admin/visitors/update-visitor",
    REMOVE_VISITOR: "v1/admin/visitors/remove-visitor",
  },
  LETTERS: {
    SAVE_LETTER: "v1/admin/letters/save-letter",
    LIST_LETTERS: "v1/admin/letters/list-letters",
    UPDATE_LETTER: "v1/admin/letters/update-letter",
    REMOVE_LETTER: "v1/admin/letters/remove-letter",
    SAVE_LETTER_EXTERNAL: "v1/admin/letters/save-letter-external",
    LIST_LETTERS_EXTERNAL: "v1/admin/letters/list-letters-external",
    UPDATE_LETTER_EXTERNAL: "v1/admin/letters/update-letter-external",
    REMOVE_LETTER_EXTERNAL: "v1/admin/letters/remove-letter-external",
  },
  DISCIPLINARY: {
    SAVE_DISCIPLINARY: "v1/admin/disciplinaryAction/add",
    LIST_DISCIPLINARY: "v1/admin/disciplinaryAction/list-all",
    DELETE_DISCIPLINARY: "v1/admin/disciplinaryAction/delete?id=",
    UPDATE_DISCIPLINARY: "v1/admin/disciplinaryAction/update",
  },
  AWARDSTUDENTS: {
    SAVE_AWARDSTUDENTS: "v1/admin/awardsStudents/add",
    LIST_AWARDSTUDENTS: "v1/admin/awardsStudents/list-all",
    DELETE_AWARDSTUDENTS: "v1/admin/awardsStudents/delete?id=",
    UPDATE_AWARDSTUDENTS: "v1/admin/awardsStudents/update",

    SAVE_CAREGORY: "v1/admin/awardsStudents/save-category",
    LIST_CAREGORY: "v1/admin/awardsStudents/list-category",
    DELETE_CAREGORY: "v1/admin/awardsStudents/delete-category?id=",
    UPDATE_CAREGORY: "v1/admin/awardsStudents/update-category",

    SAVE_SUB_CAREGORY: "v1/admin/awardsStudents/save-subcategory",
    LIST_SUB_CAREGORY: "v1/admin/awardsStudents/list-subcategory",
    LIST_SUB_CAREGORYFORDROPDOWN:
      "v1/admin/awardsStudents/list-subcategorylist",

    DELETE_SUB_CAREGORY: "v1/admin/awardsStudents/delete-subcategory?id=",
    UPDATE_SUB_CAREGORY: "v1/admin/awardsStudents/update-subcategory",
    UPDATE_SUBCATEGORY_GROUP_CATEGORY_LIST:
      "v1/admin/awardsStudents/update-list-id",

    SAVE_AWARDSCORE_NEW: "v1/admin/awardsStudents/save-score",
    LIST_AWARDSCORE: "v1/admin/awardsStudents/list-score",
    DELETE_AWARDSCORE: "v1/admin/awardsStudents/delete-score?id=",
    UPDATE_AWARDSCORE: "v1/admin/awardsStudents/update-score",
    LIST_AWARDS_SPECIFICATION: "v1/admin/awardsStudents/list-specification",
    LIST_AWARDSCORE_RANKDROPDOWN: "v1/admin/awardsStudents/list-ranklist",
    UPDATE_AWARDSTUDENTS_APPOVERD: "v1/admin/awardsStudents/update-approved",
  },
  APPLICATIONS: {
    TOTAL_RECORDS_APPLICATION: "v1/admin/application/total-records-application",
    LIST_APPLICATION: "v1/admin/application/list-application",
    LIST_GRIEVANCE: "v1/admin/application/list-graievance",
  },

  //internship
  INTERNSHIP: {
    LIST_INTERNSHIP: "v1/admin/internship/list",
    SAVE_INTERNSHIP: "v1/admin/internship/save",
    UPDATE_INTERNSHIP: "v1/admin/internship/update",
    REMOVE_INTERNSHIP: "v1/admin/internship/delete",
  },

  //Feedbacks
  FEEDBACKS: {
    ADD_FEEDBACK_TITLE: "v1/admin/feedbacks/add-feedback-title",
    UPDATE_FEEDBACK_TITLE: "v1/admin/feedbacks/update-feedback-title",
    TOTAL_RECORDS_FEEDBACK_TITLE:
      "v1/admin/feedbacks/total-records-feedback-title",
    LIST_FEEDBACK_TITLE: "v1/admin/feedbacks/list-feedback-title",
    DELETE_FEEDBACK_TITLE: "v1/admin/feedbacks/delete-feedback-title",
    LIST_SEARCHFEEDBACK_TITLE: "v1/admin/feedbacks/list-feedbacksearch-title",
    UPDATE_FEEDBACK_Rolles: "v1/admin/feedbacks/update-feedback-rolles",
    LIST_FEEDBACK_COURSES: "v1/admin/feedbacks/list-feedback-courses",

    ADD_FEEDBACK_QUESTION: "v1/admin/feedbacks/add-feedback-question",
    UPDATE_FEEDBACK_QUESTION: "v1/admin/feedbacks/update-feedback-question",
    // LIST_FEEDBACK_TITLE: "v1/admin/feedbacks/list-feedback-title",
    // DELETE_FEEDBACK_TITLE: "v1/admin/feedbacks/delete-feedback-title",
    // LIST_SEARCHFEEDBACK_TITLE: "v1/admin/feedbacks/list-feedbacksearch-title",

    // ADD_FEEDBACK_QUESTION: "v1/admin/feedbacks/add-feedback-question",
    // UPDATE_FEEDBACK_QUESTION: "v1/admin/feedbacks/update-feedback-question",
    TOTAL_RECORDS_FEEDBACK_QUESTION:
      "v1/admin/feedbacks/total-records-feedback-question",
    LIST_FEEDBACK_QUESTION: "v1/admin/feedbacks/list-feedback-question",
    DELETE_FEEDBACK_QUESTION: "v1/admin/feedbacks/delete-feedback-question",

    ADD_FEEDBACK_OPTION: "v1/admin/feedbacks/add-feedback-option",
    UPDATE_FEEDBACK_OPTION: "v1/admin/feedbacks/update-feedback-option",
    TOTAL_RECORDS_FEEDBACK_OPTION:
      "v1/admin/feedbacks/total-records-feedback-option",
    LIST_FEEDBACK_OPTION: "v1/admin/feedbacks/list-feedback-option",
    DELETE_FEEDBACK_OPTION: "v1/admin/feedbacks/delete-feedback-option",

    ADD_FEEDBACK_ANSWER: "v1/admin/feedbacks/add-feedback-answer",
    UPDATE_FEEDBACK_ANSWER: "v1/admin/feedbacks/update-feedback-answer",
    TOTAL_RECORDS_FEEDBACK_ANSWER:
      "v1/admin/feedbacks/total-records-feedback-answer",
    LIST_FEEDBACK_ANSWER: "v1/admin/feedbacks/list-feedback-answer",
    DELETE_FEEDBACK_ANSWER: "v1/admin/feedbacks/delete-feedback-answer",
    LIST_BATCH: "v1/admin/feedbacks/list-batch",
    LIST_FEEDBACK_ANSWER_OPTION:
      "v1/admin/feedbacks/list-feedback-answer-option",
    LIST_STUDENT: "v1/admin/feedbacks/list-student",
    TOTAL_RECORDS_LIST_STUDENT: "v1/admin/feedbacks/total-list-student",
    LIST_EMPLOYEE: "v1/admin/feedbacks/list-employee",
    LIST_SUMMARY_COUNT: "v1/admin/feedbacks/list-summary-count",
    LIST_SUMMARY_COUNT_EMP: "v1/admin/feedbacks/list-summary-count-emp",
    LIST_COMBINED_FEEDBACK_REPORT:
      "v1/admin/feedbacks/combined-feedback-report",
    LIST_SUBJECT_COMBINED_FEEDBACK_REPORT:
      "v1/admin/feedbacks/combined-subject-feedback-report",
    LIST_PROGRAM_COMBINED_FEEDBACK_REPORT:
      "v1/admin/feedbacks/combined-program-feedback-report",
  },

  HELPCENTER: {
    ADD_PAGE: "v1/admin/helpcenter/add-page",
    LIST_CONTENTS: "v1/admin/helpcenter/list-pages",
    UPDATE_PAGE: "v1/admin/helpcenter/update-page",
    LIST_TITLES: "v1/admin/helpcenter/list-titles",
    LIST_PARENT_TITLES: "v1/admin/helpcenter/list-parent-titles",
    ADD_MENU: "v1/admin/helpcenter/add-menu",
    LIST_TOPICS: "v1/admin/helpcenter/list-topic",
  },

  OPAC: {
    OPAC_LIST_BOOK: "v1/admin/opac/list",
    OPAC_LIST_BOOK_COUNT: "v1/admin/opac/count-list",
    COUNT_LIST_ADVANCED_SEARCH: "v1/admin/opac/count-advanced-search",
    OPAC_LIST_ADVANCED_SEARCH: "v1/admin/opac/advanced-search",
    OPAC_LIST_BOOK_TYPES: "v1/admin/opac/booktype-list",
    OPAC_LIST_ADVANCED_GENRE: "v1/admin/opac/advancegenre-list",
    OPAC_LIST_LOCATION: "v1/admin/opac/genrelocation-list",
    OPAC_LIST_LIBRARIES: "v1/admin/opac/libaries-list",
    OPAC_LIST_ADVANCED_LOCATION: "v1/admin/opac/advancesearchlocation-list",
    OPAC_LIST_SUBJECT_GENRE: "v1/admin/opac/subjectgenre-list",
  },

  REQUESTTYPE: {
    SAVE_TYPE: "v1/admin/requesttype/save-type",
    LIST_TYPE: "v1/admin/requesttype/list-type",
    UPDATE_TYPE: "v1/admin/requesttype/update-type",
    REMOVE_TYPE: "v1/admin/requesttype/delete?id=",
    GET_PAGE: "v1/admin/requesttype/get-type",
    //REMOVE_COURSES: "v1/admin/courses/remove-course",
    LIST_FEEDBACK_ANSWER: "v1/admin/feedbacks/list-feedback-answer",
    DELETE_FEEDBACK_ANSWER: "v1/admin/feedbacks/delete-feedback-answer",
  },

  OFFICEDOCUMENT: {
    LIST_OFFICE: "v1/admin/office/list-all",
    SAVE_OFFICEDOCUMENT: "v1/admin/office/save",
    DEL_OFF_DOC: "v1/admin/office/delete",
  },
  LIBRARIES: {
    LIST_LIBRARIES: "v1/admin/libraries/list",
    SAVE_LIBRARIES: "v1/admin/libraries/save",
    UPDATE_LIBRARIES: "v1/admin/libraries/update",
    REMOVE_LIBRARIES: "v1/admin/libraries/delete",
    DASHBOARD_COUNT: "v1/admin/libraries/dashboard/count-data",
    DASHBOARD_BOOK_DATA: "v1/admin/libraries/dashboard/search",

    LIST_BOOK_TYPES: "v1/admin/libraries/types/list",
    SAVE_BOOK_TYPES: "v1/admin/libraries/types/save",
    UPDATE_BOOK_TYPES: "v1/admin/libraries/types/update",
    REMOVE_BOOK_TYPES: "v1/admin/libraries/types/delete",

    LIST_GROUPS: "v1/admin/libraries/groups/list",
    SAVE_GROUPS: "v1/admin/libraries/groups/save",
    UPDATE_GROUPS: "v1/admin/libraries/groups/update",
    REMOVE_GROUPS: "v1/admin/libraries/groups/delete",

    GET_ITEM_CONFIG: "v1/admin/libraries/book/item-config",
    ITEM_CONFIG_DATA: "v1/admin/libraries/book/item-config-data",
    UPDATE_ITEM_CONFIG: "v1/admin/libraries/book/update-item-config",
    SAVE_ATTRIBUTES: "v1/admin/libraries/book/save-attributes",
    UPDATE_ATTRIBUTES: "v1/admin/libraries/book/update-attributes",
    LIST_ATTRIBUTES: "v1/admin/libraries/book/list-attributes",
    LIST_ATTRIBUTES_VALUES: "v1/admin/libraries/book/list-attributes-values",
    GET_NEXT_ACCESS_NUMBER: "v1/admin/libraries/book/get-next-access-number",

    LIST_ADVANCED_SEARCH: "v1/admin/libraries/book/advanced-search",
    LIST_ADVANCED_SEARCH_STOCK: "v1/admin/libraries/book/advanced-search-stock",
    COUNT_LIST_ADVANCED_SEARCH_STOCK:
      "v1/admin/libraries/book/count-advanced-search-stock",
    COUNT_LIST_ADVANCED_SEARCH: "v1/admin/libraries/book/count-advanced-search",
    COUNT_LIST_BOOK: "v1/admin/libraries/book/count-list",

    LIST_DYNAMIC_ATTRIBUTES:
      "v1/admin/libraries/book/list-attributes-with-value",
    LIST_BOOK: "v1/admin/libraries/book/list",
    SAVE_BOOK: "v1/admin/libraries/book/save",
    UPDATE_BOOK: "v1/admin/libraries/book/update",
    REMOVE_BOOK: "v1/admin/libraries/book/delete",
    CALL_OTHER_API: "v1/admin/libraries/book/get-datafrom-api",
    UPDATE_DDC_NUMBER: "v1/admin/libraries/book/update-ddcnumber",
    SAVE_MARC_BOOK: "v1/admin/libraries/book/save-marc-book",
    GET_MARC_STRUCTURE: "v1/admin/libraries/book/get-marcstructure",
    GET_MARC_TAG_AND_SUBFIELDS:
      "v1/admin/libraries/book/get-marc-tag-and-subfields", //for new entryand edit form
    DELETE_BOOK_ITEM: "v1/admin/libraries/book/item-list-delete",

    SAVE_ITEM_QUICK_ENTRY: "v1/admin/libraries/book/save-item-quick-entry",

    PRE_CHECK_QUICK_ENTRY: "v1/admin/libraries/book/pre-check-quick-entry",

    LIST_LOCATIONS: "v1/admin/libraries/locations/list",
    SAVE_LOCATIONS: "v1/admin/libraries/locations/save",
    UPDATE_LOCATIONS: "v1/admin/libraries/locations/update",
    REMOVE_LOCATIONS: "v1/admin/libraries/locations/delete",

    LIST_GENRE: "v1/admin/libraries/genre/list",
    SAVE_GENRE: "v1/admin/libraries/genre/save",
    UPDATE_GENRE: "v1/admin/libraries/genre/update",
    REMOVE_GENRE: "v1/admin/libraries/genre/delete",
    UPDATE_GENRE_ORDER: "v1/admin/libraries/genre/update-genre-order",
    LIST_SUBJECT_GENRE: "v1/admin/libraries/genre/subject-genre",
    LIST_LOCATION: "v1/admin/libraries/genre/location",
    LIST_ADVANCED_LOCATION:
      "v1/admin/libraries/genre/advance-search-list-location",
    LIST_ADVANCED_GENRE: "v1/admin/libraries/genre/advance-search-list-genre",

    LIST_MARC_TAG: "v1/admin/libraries/marc/tags",
    LIST_MARC_TAG_SUBFIELD: "v1/admin/libraries/marc/subfields",

    SAVE_FRAMESWORK: "v1/admin/libraries/marc/saveframe",
    UPDATE_FRAMESWORK: "v1/admin/libraries/marc/frame-update",
    DELETE_FRAMESWORK: "v1/admin/libraries/marc/frame-delete",
    DELETE_TAG_FRAMESWORK: "v1/admin/libraries/marc/frame-tag-delete",
    LIST_MARC_FRAMESWORK: "v1/admin/libraries/marc/list",
    LIST_MARC_CONFIGURATION_FRAMEWORK: "v1/admin/libraries/marc/configurelist",
    SAVE_FRAMESWORK_CONFIGURE: "v1/admin/libraries/marc/saveconfigure",
    SAVE_SUBTAG_FRAMESWORK: "v1/admin/libraries/marc/savesubtag",

    LIST_USER_CATEGORY: "v1/admin/libraries/user-category/list",
    SAVE_USER_CATEGORY: "v1/admin/libraries/user-category/save",
    UPDATE_USER_CATEGORY: "v1/admin/libraries/user-category/update",
    REMOVE_USER_CATEGORY: "v1/admin/libraries/user-category/delete",

    SEARCH_USER: "v1/admin/libraries/book-issue/search",
    SAVE_USER: "v1/admin/libraries/book-issue/save",

    LIST_ITEM_CIRCULATION: "v1/admin/libraries/item-circulation/list",
    CIRCULATION_COUNT: "v1/admin/libraries/item-circulation/circulation-count",
    LIST_ITEM_CIRCULATION_BY_ITEM:
      "v1/admin/libraries/item-circulation/list-by-item",
    SAVE_ITEM_CIRCULATION: "v1/admin/libraries/item-circulation/save",
    RENEW_ITEM: "v1/admin/libraries/item-circulation/renew",
    RETURN_ITEM: "v1/admin/libraries/item-circulation/return",
    LIST_ITEM: "v1/admin/libraries/item-circulation/list",

    LIST_TOP_USERS: "v1/admin/libraries/item-circulation/list-top-users",
    COUNT_TOP_USERS: "v1/admin/libraries/item-circulation/top-users-count",

    SEARCH_BOOK: "v1/admin/libraries/library-item/search",
    LIST_RESEVEDDATA: "v1/admin/libraries/item-circulation/list-reseved-data",

    VERIFY_USER_CATEGORY: "v1/admin/libraries/user-category/verify",

    //LAXMI-LIBRARY
    LIST_ITEM_BOOK: "v1/admin/libraries/barcode-print/item-list",
    CREATE_TEMPLATE_FILE: "v1/admin/libraries/barcode-print/create-template",
    UPDATE_TEMPLATE_FILE: "v1/admin/libraries/barcode-print/update-template",
    GET_TEMPLATE_CONTENT:
      "v1/admin/libraries/barcode-print/barcode-template-content",
    GET_BARCODE_IMAGE:
      "v1/admin/libraries/barcode-print/generate-barcode-image",

    SAVE_SERIALS: "v1/admin/libraries/library-serials/save",
    LIST_SERIALS: "v1/admin/libraries/library-serials/list",
    SERIALS_SUMMARY: "v1/admin/libraries/library-serials/serials-summary",
    COUNT_SERIAL_SUMMARY:
      "v1/admin/libraries/library-serials/count-serial-summary",
    DELETE_SERIALS: "v1/admin/libraries/library-serials/delete",
    UPDATE_SERIALS: "v1/admin/libraries/library-serials/update",

    SAVE_READING_BOOK: "v1/admin/libraries/reader-corner/save",
    SAVE_READING_ATTENDANCE: "v1/admin/libraries/reader-corner/save-attendance",
    LIST_READING_BOOK: "v1/admin/libraries/reader-corner/list",
    UPDATE_READING_BOOK: "v1/admin/libraries/reader-corner/update",

    LIST_ATTENDANCE_REPORT: "v1/admin/libraries/attendance/list",
    LIST_ATTENDANCEV2_REPORT: "v1/admin/libraries/attendance/list-formate",

    EDIT_ATTENDANCE_REPORT: "v1/admin/libraries/attendance/edit-attendance",
    COUNT_LIST_ATTENDANCE: "v1/admin/libraries/attendance/count-list",
    LIST_ATTENDANCE: "v1/admin/libraries/attendance/attendance-list",

    LIST_READING_LIBRARY: "v1/admin/libraries/reading-history/list-library",
    LIST_READING_GENRE: "v1/admin/libraries/reading-history/list-genre",
    LIST_OVERALL_CATALOGUE: "v1/admin/libraries/catalogue/overall-list",
    LIST_SUMMARY_CATALOGUE: "v1/admin/libraries/catalogue/summary-list",

    BOOK_RESERVE: "v1/admin/libraries/item-reservation/reserve",
    CHECK_RESERVATION: "v1/admin/libraries/item-reservation/check",
    CANCEL_RESERVE: "v1/admin/libraries/item-reservation/cancel",
    LIST_USER_RESERVED:
      "v1/admin/libraries/item-reservation/list-user-reserved",

    FINE_CALC: "v1/admin/libraries/transaction/fine-calc",
    LIST_FINE: "v1/admin/libraries/transaction/list-fine",
    COUNT_FINE: "v1/admin/libraries/transaction/count-fine",
    PAY_FINE: "v1/admin/libraries/transaction/pay-fine",

    LIST_USER: "v1/admin/libraries/user/list",
    LIST_FINE_SUMMERY: "v1/admin/libraries/fine-management/list-fine-summery",
    GET_MARC_STRUCTURE_NEW: "v1/admin/libraries/book/get-marcstructure-new",

    SAVE_TRANSACTION_PENALITY:
      "v1/admin/libraries/book/save-transaction-penality",
    UPDATE_CIRCULATION_STATUS:
      "v1/admin/libraries/book/update-circulation-status",
    SAVE_VERIFY_TITLE: "v1/admin/libraries/libraryStock/save-title",
    UPDATE_VERIFY_TITLE: "v1/admin/libraries/libraryStock/update-title",
    LIST_VERIFY_TITLE: "v1/admin/libraries/libraryStock/list-title",

    SAVE_VERIFICATION: "v1/admin/libraries/libraryStock/save-verification",
    STOCK_LOCATION_UPDATE:
      "v1/admin/libraries/libraryStock/stock-location-update",
    LIST_VERIFICATION: "v1/admin/libraries/libraryStock/list-verification",
    VERIFIED_COUNT: "v1/admin/libraries/libraryStock/verified-count",
    VERIFIED_ITEMS: "v1/admin/libraries/libraryStock/verified-items",
    PENDING_COUNT: "v1/admin/libraries/libraryStock/pending-count",

    COUNT_BORROWED_BOOK: "v1/admin/libraries/book/borrow-count-list",
    LIST_BORROWED_BOOK: "v1/admin/libraries/book/borrow-list",

    TRANSFER_ITEMS: "v1/admin/libraries/itemTransfer/transfer-items",
    TRANSFER_SUMMARY: "v1/admin/libraries/itemTransfer/transfer-summary",
    TRANSFER_STATISTICS: "v1/admin/libraries/itemTransfer/transfer-statistics",
    TRANSFERED_DATA: "v1/admin/libraries/itemTransfer/transfered-data",
    LIST_TRANSFER_YEARS: "v1/admin/libraries/itemTransfer/list-transfer-years",
    EDIT_LIST_TRANSFER: "v1/admin/libraries/itemTransfer/edit-transfer",
    COUNT_TRANSFER_SUMMARY:
      "v1/admin/libraries/itemTransfer/count-transfer-summary",
    COUNT_TRANSFER_STATISTICS:
      "v1/admin/libraries/itemTransfer/count-transfer-statistics",
    COUNT_TRANSFER_SUMMARY_STATIS:
      "v1/admin/libraries/itemTransfer/count-transfer-summary-statistics",
    TRANSFERED_DATA_STATIS:
      "v1/admin/libraries/itemTransfer/transfered-data-statistics",
    SAVE_LIBRARIES_LOCKUSER: "v1/admin/libraries/itemTransfer/save-lockuser",
    LIST_LIBRARIES_LOCKUSER: "v1/admin/libraries/itemTransfer/list-lockuser",
    UPDATE_LIBRARIES_LOCKUSER:
      "v1/admin/libraries/itemTransfer/update-lockuser",
    UPDATE_LIBRARIES_LOCKUNLOCKUSER:
      "v1/admin/libraries/itemTransfer/update-lockunlockuser",
    DELETE_LIBRARIES_LOCKUSER:
      "v1/admin/libraries/itemTransfer/delete-lockuser",

    MANUAL_SAVE_ATTENDANCE:
      "v1/admin/libraries/reader-corner/manual-save-attendance",
    DELETE_GATE_ENTRY: "v1/admin/libraries/reader-corner/delete-gate-entry",
    NOT_HAVING_DCC: "v1/admin/libraries/nothavingdcc/list",
    LIST_STATUS: "v1/admin/libraries/itemTransfer/liststatus",
    ADD_PUBLICATION: "v1/admin/libraries/types/save-publication",
    EDIT_PUBLICATION: "v1/admin/libraries/types/update-publication",
    LIST_PUBLICATION: "v1/admin/libraries/types/list-publication",
    DELETE_PUBLICATION: "v1/admin/libraries/types/delete-publication",

    ADD_AUTHOR: "v1/admin/libraries/types/save-author",
    EDIT_AUTHOR: "v1/admin/libraries/types/update-author",
    LIST_AUTHOR: "v1/admin/libraries/types/list-author",
    DELETE_AUTHOR: "v1/admin/libraries/types/delete-author",
    LIST_LOCK_STATUS: "v1/admin/libraries/itemTransfer/list-lock-status",
  },
  // ALUMNI: {
  //   SEARCH_USER: "v1/admin/alumni/search",
  //   SAVE_USER: "v1/admin/alumni/save",
  //   LIST_USER: "v1/admin/alumni/list",
  //   SAVE_MAILMERGE: "v1/admin/alumni/save-bulk-mergemail",
  //   SAVE_MAILMERGE_STAFF: "v1/admin/alumni/save-bulk-mergemailstaff",
  // },
  ALUMNI: {
    SEARCH_USER: "v1/admin/alumni/search",
    SAVE_USER: "v1/admin/alumni/save",
    LIST_USER: "v1/admin/alumni/list",
    SAVE_MAILMERGE: "v1/admin/alumni/save-bulk-mergemail",
    LIST_MAILMERGE: "v1/admin/alumni/list-bulk-mergemail",
    SAVE_ENROLLMENTFORM: "v1/admin/alumni/save-bulk-enrolment",

    SAVE_MAILMERGE_STAFF: "v1/admin/alumni/save-bulk-mergemailstaff",
    STAFF_LIST_MAILMERGE: "v1/admin/alumni/list-bulk-mergemailstaff",
  },
  FINANCE: {
    SAVE_ACCOUNTBOOK: "v1/admin/finance/account-book/save",
    LIST_ACCOUNTBOOK: "v1/admin/finance/account-book/list",
    DELETE_ACCOUNTBOOK: "v1/admin/finance/account-book/delete",
    UPDATE_ACCOUNTBOOK: "v1/admin/finance/account-book/update",

    SAVE_SUPPLIERS: "v1/admin/finance/add-suppliers/save",
    LIST_SUPPLIERS: "v1/admin/finance/add-suppliers/list",
    COUNT_ADVANCED_SEARCH:
      "v1/admin/finance/add-suppliers/count-advanced-search",
    UPDATE_SUPPLIERS: "v1/admin/finance/add-suppliers/update",
    REMOVE_SUPPLIERS: "v1/admin/finance/add-suppliers/delete",

    SAVE_DONORS: "v1/admin/finance/donors/save",
    LIST_DONORS: "v1/admin/finance/donors/list",
    COUNT_ADVANCED_SEARCH: "v1/admin/finance/donors/count-advanced-search",
    UPDATE_DONORS: "v1/admin/finance/donors/update",
    REMOVE_DONORS: "v1/admin/finance/donors/delete",

    SAVE_LEDGERS: "v1/admin/finance/ledgers/save",
    LIST_LEDGERS: "v1/admin/finance/ledgers/list",
    LIST_COUNT_LEDGERS: "v1/admin/finance/ledgers/count-list",
    DELETE_LEDGERS: "v1/admin/finance/ledgers/delete",
    UPDATE_LEDGERS: "v1/admin/finance/ledgers/update",
    LIST_CATEGORY_TRANSACTION: "v1/admin/finance/ledgers/transaction-list",

    SAVE_CATEGORY: "v1/admin/finance/category/save",
    LIST_CATEGORY: "v1/admin/finance/category/list",
    LIST_COUNT_CATEGORY: "v1/admin/finance/category/count-list",
    DELETE_CATEGORY: "v1/admin/finance/category/delete",
    UPDATE_CATEGORY: "v1/admin/finance/category/update",

    SAVE_TRANSACTION: "v1/admin/finance/fund-transaction/save",
    LIST_TRANSACTION: "v1/admin/finance/fund-transaction/list",
    UPDATE_TRANSACTION: "v1/admin/finance/fund-transaction/update",
    DELETE_TRANSACTION: "v1/admin/finance/fund-transaction/delete",
    LIST_TRANSACTION_EXPORT: "v1/admin/finance/fund-transaction/export-list",

    LIST_LEDGERS_TRANSACTION: "v1/admin/finance/ledgers-transaction/list",
    LIST_OPENING_BALANCE: "v1/admin/finance/ledgers-transaction/ob-list",
  },
  CONVOCATION: {
    LIST_CONVOCATION: "v1/admin/convocation/list",
    EDIT_CONVOCATION: "v1/admin/convocation/update",
    REMOVE_CONVOCATION: "v1/admin/convocation/remove",
  },

  ADMISSIONS: {
    lIST_APPLICATIONS_UG: "v1/admin/ug/applications/list_ug_applications",
    lIST_APPLICATIONS_UG_NEW:
      "v1/admin/ug/applications/list_ug_applications_new",
    lIST_APPLICATIONS_UG_INCOMPLETE:
      "v1/admin/ug/applications/list_ug_applications_incomplete",
    VIEW_PG_APPLICATION_PERSONAL_INFO:
      "v1/admin/pg/applications/view_personal_info",
    VIEW_PG_APPLICATION_UG_MARKS: "v1/admin/pg/applications/view_ug_marks",
    VIEW_PG_APPLICATION_COURSE_DETAILS:
      "v1/admin/pg/applications/view_course_details",
    VIEW_PG_APPLICATION_SEARCH: "v1/admin/pg/applications/search_details",
    UPDATE_PG_PERSONAL_FIELD:
      "v1/admin/pg/applications/update_personal_field_value",
    VIEW_PG_CERTIFICATE: "v1/admission/pg/application/certificate",
    PG_DAY_COLLECTION: "v1/admin/pg/applications/day_collection",
    PG_DAY_SUMMARY: "v1/admin/pg/applications/day_summary",
    PG_SPLIT_ERROR: "v1/admin/pg/applications/split_error",
    PG_SPLIT_STATUS: "v1/admin/pg/applications/track_split_status",

    PG_SELECTION_ROSTER_COUNT:
      "v1/admin/pg/selection/list_ug_selection_roster_count",
    PG_SELECTION_PROCESS_LIST_ALL:
      "v1/admin/pg/selection/list_ug_applications_for_selection",
    PG_SELECTION_PROCESS_SAVE: "v1/admin/pg/selection/save_selection_list",
    PG_SELECTION_PROCESS_SAVE_MANUAL:
      "v1/admin/pg/selection/save_manual_selection",
    PG_SELECTION_PROCESS_SAVEDLIST: "v1/admin/pg/selection/list_selection",
    PG_SELECTION_CANDIDATE_CUTOFF:
      "v1/admin/pg/selection/application_withcutoff",
    PG_SELECTION_UPDATE_CANDIDATE:
      "v1/admin/pg/selection/update_selection_candidate",
    PG_SELECTION_REMOVE_CANDIDATE:
      "v1/admin/pg/selection/remove_selection_candidate",
    PG_SELECTION_SEND_SMS: "v1/admin/pg/selection/send_selection_sms",
    PG_SELECTION_RESEND_SMS: "v1/admin/pg/selection/resend_selection_sms",
    PG_SELECTION_SEND_MAIL: "v1/admin/pg/selection/send_selection_mail",
    PG_CANDIDATE_APPLIED_COURSE: "v1/admin/pg/admission/list_applied_courses",
    PG_CANDIDATE_SAVE_ADMISSION: "v1/admin/pg/admission/save_new_admission",
    PG_CANDIDATE_LIST_ADMISSION: "v1/admin/pg/admission/ug_admitted_list",
    PG_CANDIDATE_REVERT_ADMISSION: "v1/admin/pg/admission/revert_admission",

    lIST_APPLICATIONS_PG: "v1/admin/pg/applications/list_pg_applications",
    lIST_APPLICATIONS_PG_NEW:
      "v1/admin/pg/applications/list_pg_applications_new",
    lIST_APPLICATIONS_PG_INCOMPLETE:
      "v1/admin/pg/applications/list_pg_applications_incomplete",
    VIEW_UG_APPLICATION_PERSONAL_INFO:
      "v1/admin/ug/applications/view_personal_info",
    VIEW_UG_APPLICATION_UG_MARKS: "v1/admin/ug/applications/view_ug_marks",
    VIEW_UG_APPLICATION_COURSE_DETAILS:
      "v1/admin/ug/applications/view_course_details",
    VIEW_UG_APPLICATION_SEARCH: "v1/admin/ug/applications/search_details",
    UPDATE_UG_PERSONAL_FIELD:
      "v1/admin/ug/applications/update_personal_field_value",
    // VIEW_UG_CERTIFICATE: "v1/ug/application/view_ug_certificates",
    VIEW_UG_CERTIFICATE: "v1/admission/ug/application/certificate",

    UG_DAY_COLLECTION: "v1/admin/ug/applications/day_collection",
    UG_DAY_SUMMARY: "v1/admin/ug/applications/day_summary",
    UG_SPLIT_ERROR: "v1/admin/ug/applications/split_error",
    UG_SPLIT_STATUS: "v1/admin/ug/applications/track_split_status",
    UG_SALE_OF_APPLICATIONS: "v1/admin/ug/applications/sale_of_applications",
    PG_SALE_OF_APPLICATIONS: "v1/admin/pg/applications/sale_of_applications",

    UG_SELECTION_ROSTER_COUNT:
      "v1/admin/ug/selection/list_ug_selection_roster_count",
    UG_SELECTION_PROCESS_LIST_ALL:
      "v1/admin/ug/selection/list_ug_applications_for_selection",
    UG_SELECTION_PROCESS_SAVE: "v1/admin/ug/selection/save_selection_list",
    UG_SELECTION_PROCESS_SAVE_MANUAL:
      "v1/admin/ug/selection/save_manual_selection",
    UG_SELECTION_PROCESS_SAVEDLIST: "v1/admin/ug/selection/list_selection",
    UG_SELECTION_CANDIDATE_CUTOFF:
      "v1/admin/ug/selection/application_withcutoff",
    UG_SELECTION_UPDATE_CANDIDATE:
      "v1/admin/ug/selection/update_selection_candidate",
    UG_SELECTION_REMOVE_CANDIDATE:
      "v1/admin/ug/selection/remove_selection_candidate",
    UG_SELECTION_SEND_SMS: "v1/admin/ug/selection/send_selection_sms",
    UG_SELECTION_RESEND_SMS: "v1/admin/ug/selection/resend_selection_sms",
    UG_SELECTION_SEND_MAIL: "v1/admin/ug/selection/send_selection_mail",
    UG_CANDIDATE_APPLIED_COURSE: "v1/admin/ug/admission/list_applied_courses",
    UG_CANDIDATE_SAVE_ADMISSION: "v1/admin/ug/admission/save_new_admission",
    UG_CANDIDATE_LIST_ADMISSION: "v1/admin/ug/admission/ug_admitted_list",
    UG_CANDIDATE_LIST_NON_ADMISSION:
      "v1/admin/ug/admission/ug_non_admitted_list",
    PG_CANDIDATE_LIST_NON_ADMISSION:
      "v1/admin/ug/admission/pg_non_admitted_list",
    UG_CANDIDATE_REVERT_ADMISSION: "v1/admin/ug/admission/revert_admission",
    UG_REJECT_APPLICATION: "v1/admin/ug/application/reject",

    // MPHIL
    lIST_APPLICATIONS_MPHIL: "v1/admin/mphil/applications/list_pg_applications",
    VIEW_MPHIL_APPLICATION_PERSONAL_INFO:
      "v1/admin/mphil/applications/view_personal_info",
    VIEW_MPHIL_APPLICATION_PG_MARKS:
      "v1/admin/mphil/applications/view_ug_marks",
    VIEW_MPHIL_APPLICATION_COURSE_DETAILS:
      "v1/admin/mphil/applications/view_course_details",
    VIEW_MPHIL_APPLICATION_SEARCH: "v1/admin/mphil/applications/search_details",
    UPDATE_MPHIL_PERSONAL_FIELD:
      "v1/admin/mphil/applications/update_personal_field_value",
    VIEW_MPHIL_CERTIFICATE: "v1/mphil/application/view_pg_certificates",
    MPHIL_DAY_COLLECTION: "v1/admin/mphil/applications/day_collection",
    MPHIL_DAY_SUMMARY: "v1/admin/mphil/applications/day_summary",
    MPHIL_SPLIT_ERROR: "v1/admin/mphil/applications/split_error",
    MPHIL_SPLIT_STATUS: "v1/admin/mphil/applications/track_split_status",

    MPHIL_SELECTION_ROSTER_COUNT:
      "v1/admin/mphil/selection/list_ug_selection_roster_count",
    MPHIL_SELECTION_PROCESS_LIST_ALL:
      "v1/admin/mphil/selection/list_ug_applications_for_selection",
    MPHIL_SELECTION_PROCESS_SAVE:
      "v1/admin/mphil/selection/save_selection_list",
    MPHIL_SELECTION_PROCESS_SAVE_MANUAL:
      "v1/admin/mphil/selection/save_manual_selection",
    MPHIL_SELECTION_PROCESS_SAVEDLIST:
      "v1/admin/mphil/selection/list_selection",
    MPHIL_SELECTION_CANDIDATE_CUTOFF:
      "v1/admin/mphil/selection/application_withcutoff",
    MPHIL_SELECTION_UPDATE_CANDIDATE:
      "v1/admin/mphil/selection/update_selection_candidate",
    MPHIL_SELECTION_REMOVE_CANDIDATE:
      "v1/admin/mphil/selection/remove_selection_candidate",
    MPHIL_SELECTION_SEND_SMS: "v1/admin/mphil/selection/send_selection_sms",
    MPHIL_SELECTION_RESEND_SMS: "v1/admin/mphil/selection/resend_selection_sms",
    MPHIL_SELECTION_SEND_MAIL: "v1/admin/mphil/selection/send_selection_mail",
    MPHIL_CANDIDATE_APPLIED_COURSE:
      "v1/admin/mphil/admission/list_applied_courses",
    MPHIL_CANDIDATE_SAVE_ADMISSION:
      "v1/admin/mphil/admission/save_new_admission",
    MPHIL_CANDIDATE_LIST_ADMISSION: "v1/admin/mphil/admission/ug_admitted_list",
    MPHIL_CANDIDATE_REVERT_ADMISSION:
      "v1/admin/mphil/admission/revert_admission",

    LIST_HSC_SUBJECTS: "v1/admin/ug/hsc/list_hsc_subjects",

    SPLIT_REFERENCE_LIST: "v1/admin/split_reference/list_all",
    SPLIT_REFERENCE_UPDATE: "v1/admin/split_reference/update_split_reference",
    SPLIT_REFERENCE_NEW: "v1/admin/split_reference/new_split_reference",

    // Accounts
    ACCOUNTS_GROUP_NEW: "v1/admin/accounts/group/save",
    ACCOUNTS_GROUP_ALL: "v1/admin/accounts/group/list_all",
    ACCOUNTS_GROUP_STATUS_CHANGE: "v1/admin/accounts/group/status_change",
    ACCOUNTS_GROUP_REMOVE: "v1/admin/accounts/group/remove",
    ACCOUNTS_GROUP_UPDATE: "v1/admin/accounts/group/update",

    ACCOUNTS_CATEGORY_NEW: "v1/admin/accounts/category/save",
    ACCOUNTS_CATEGORY_ALL: "v1/admin/accounts/category/list_all",
    ACCOUNTS_CATEGORY_STATUS_CHANGE: "v1/admin/accounts/category/status_change",
    ACCOUNTS_CATEGORY_REMOVE: "v1/admin/accounts/category/remove",
    ACCOUNTS_CATEGORY_UPDATE: "v1/admin/accounts/category/update",

    ACCOUNTS_FEE_ASSIGN_SAMPLE_XLS:
      "v1/admin/accounts/feeassing/students_list_for_sample_excel",

    LIST_UG_FOR_PG: "v1/admin/ug/courses/list_ug_majors",
    LIST_ALLOTMENT: "v1/admin/ug/courses/list_pages",
    UPDATE_ALLOTMENT: "v1/admin/ug/courses/update_page",
    ADD_ALLOTMENT: "v1/admin/ug/courses/add_page",

    LIST_MAIL_TEMPLATES: "v1/admin/ug/mailtemplates/list_templates",
    UPDATE_MAIL_TEMPLATES: "v1/admin/ug/mailtemplates/update_template",
    ADD_MAIL_TEMPLATES: "v1/admin/ug/mailtemplates/add_template",
    DELETE_MAIL_TEMPLATES: "v1/admin/ug/mailtemplates/delete_template",

    LIST_SMS_TEMPLATES: "v1/admin/ug/smstemplates/list_templates",
    UPDATE_SMS_TEMPLATES: "v1/admin/ug/smstemplates/update_template",
    ADD_SMS_TEMPLATES: "v1/admin/ug/smstemplates/add_template",
    DELETE_SMS_TEMPLATES: "v1/admin/ug/smstemplates/delete_template",
    UG_APPLICATION_VERIFIED: "v1/admin/ug/admission/list_verification",
    UG_APPLICATION_UPDATE: "v1/admin/ug/admission/update_verification",
    PG_APPLICATION_VERIFIED: "v1/admin/pg/admission/list_verification",
    PG_APPLICATION_UPDATE: "v1/admin/pg/admission/update_verification",
  },
  NOTIFICATION: {
    SAVE_NOTIFICATION: "v1/admin/notification/save",
    LIST_NOTIFICATION_DETAILS: "v1/admin/notification/details",
    LIST_NOTIFICATION: "v1/admin/notification/list",
    DELETE_NOTIFICATION: "v1/admin/notification/delete",
  },

  CRON_JOBS: {
    TRACK_BULK_STATUS: "v1/student/track_bulk_status",
    SPLIT_CALL: "v1/student/call_split",
    CCA_PAYOUT: "v1/ccareports/payout",
    AUTO_ABSENT_SMS: "v1/oa/dayorder/auto-absent-sms",
    AUTO_ABSENT_SMS_MONTH: "v1/oa/dayorder/auto-absent-sms-month",
    AUTO_FINE_UPDATE: "v1/oa/stupay/auto-fine-update",
    AUTO_FEE_DELETE: "v1/oa/stupay/fees-auto-delete",
  },

  HALLTICKET_SETTING: {
    SAVE_SETTINGS: "v1/admin/hallticket/save",
    SAVE_SETTINGS_BASIC: "v1/admin/hallticket/add",
    UPDATE_SETTINGS_BASIC: "v1/admin/hallticket/update",
    LIST_SETTINGS_BASIC: "v1/admin/hallticket/list",
    SAVE_EXAMINATION: "v1/admin/hallticket/saveexam",
    LIST_EXAMINATION: "v1/admin/hallticket/listexam",
    UPDATE_EXAMINATION: "v1/admin/hallticket/updateexam",
    DELETE_EXAMINATION: "v1/admin/hallticket/deleteexam",
    SAVE_STUDENT_STATUS: "v1/admin/hallticket/savestatus",
    LIST_STUDENT_STATUS: "v1/admin/hallticket/liststatus",

    LIST_EMPLOYEES: "v1/admin/hallticket/listemployee",
  },
  ELECTIVESELECTION: {
    LIST_SUJECT: "v1/admin/electiveselection/list-subject",
    SAVE_ELECTIVESELECTION: "v1/admin/electiveselection/save",
    LIST_ELECTIVESECTION: "v1/admin/electiveselection/list",
    LIST_ELECTIVETITLE: "v1/admin/electiveselection/list-elective",
    UPDATE_ELECTIVETITLE: "v1/admin/electiveselection/update",
    REMOVE_ELECTIVE: "v1/admin/electiveselection/delete",
    LIST_COURSES_ELECTIVE: "v1/admin/electiveselection/list-courses",
    LIST_SELECT_COUNT: "v1/admin/electiveselection/list-count",
    LIST_UNSELECT_COUNT: "v1/admin/electiveselection/list-unselectstudent",
    SAVE_ELECTIVESELECTION_STUDENT: "v1/admin/electiveselection/save-student",
    REMOVE_ELECTIVE_STUDENT: "v1/admin/electiveselection/delete-student",
    LIST_LAST_LOG: "v1/admin/electiveselection/list-log",
  },
  SERVICEORGANIZATION: {
    SAVE_SERVICEORGANIZATION: "v1/admin/serviceorganization/save",
    EDIT_SERVICEORGANIZATION: "v1/admin/serviceorganization/update",
    LIST_SERVICEORGANIZATION: "v1/admin/serviceorganization/list",
    REMOVE_SERVICEORGANIZATION: "v1/admin/serviceorganization/delete",
    LIST_SERVICEORGANIZATIONTITLE:
      "v1/admin/serviceorganization/list-organizationtitle",
    SAVE_SERVICESELECTION: "v1/admin/serviceorganization/save-selection",
    LIST_SERVICESELECTION: "v1/admin/serviceorganization/list-selection",
    UPDATE_SERVICESELECTION: "v1/admin/serviceorganization/update-selection",
    REMOVE_SERVICESELECTION: "v1/admin/serviceorganization/delete-selection",
    SAVE_SERVICESELECTION_STUDENT: "v1/admin/serviceorganization/save-student",
    LIST_SERVICESELECTION_COUNT:
      "v1/admin/serviceorganization/list-servicecount",
    REMOVE_SERVICESELECTION_STUDENT:
      "v1/admin/serviceorganization/delete-student",
    LIST_SERVICESELECTION_LAST_LOG: "v1/admin/serviceorganization/list-log",
    SAVE_SERVICEORGANIZATION_TITLE: "v1/admin/serviceorganization/save-title",
    LIST_SERVICEORGANIZATION_TITLE: "v1/admin/serviceorganization/list-title",
    EDIT_SERVICEORGANIZATION_TITLE: "v1/admin/serviceorganization/update-title",
    LIST_UNSELECT_COUNT: "v1/admin/serviceorganization/list-unselectstudent",
    LIST_ATTENDANCE_SERVICE_ORG_REPORT:
      "v1/admin/serviceorganization/list-attreport",
    LIST_ATTENDANCE_SERVICE_ORG_LIST: "v1/admin/serviceorganization/list-att",
    SERVICE_ATT_STU_LIST: "v1/admin/serviceorganization/list-att-stu",
  },

  CERTIFICATE: {
    ADD_CERTIFCATE_TYPE: "v1/admin/certificate/save",
    UPDATE_CERTIFCATE_TYPE: "v1/admin/certificate/update",
    LIST_CERTIFCATE_TYPE: "v1/admin/certificate/list",
    REMOVE_CERTIFCATE_TYPE: "v1/admin/certificate/delete",
    SAVE_CERTIFICATE_TEMP: "v1/admin/certificate/savetemplate",
    EDIT_CERTIFICATE_TEMP: "v1/admin/certificate/updatetemplate",
    LIST_CERTIFICATE_TEMP: "v1/admin/certificate/listtemplate",
    REMOVE_CERTIFCATE_TEMP: "v1/admin/certificate/deletetemp",
    LIST_NOC_TYPE: "v1/admin/certificate/listnoc",
    CUSTOM_ATTRIBUTES: "v1/admin/certificate/listcustomattribute",
    PDF_PREVIEW: "v1/admin/certificate/pdfpreview",
    ISSUE_STUDENTS_DATA: "v1/admin/certificate/savestudentdata",
    SAVE_SERIAL_RULES: "v1/admin/certificate/saveserialrules",
    LIST_SERIAL_RULES: "v1/admin/certificate/listserialno",
    UPDATE_SERIAL_RULES: "v1/admin/certificate/updateserialno",
    REMOVE_SERIAL_RULES: "v1/admin/certificate/deleteserialno",
    EDIT_UPDATE_STUDENT_CERTIFICATECONTENT:
      "v1/admin/certificate/updatecertificatecontent",
    CERTIFICATE_LIST_ISSUEDRECORD: "v1/admin/certificate/listissuedrecord",

    DATASOURCE_FUNCTIONS: {
      STUDENT_PROFILE_DATA:
        "v1/admin/certificate/data_sourcefunction/student-profile-data",
      SERIAL_DATA: "v1/admin/certificate/data_sourcefunction/serial-data",
      TCSERIAL_DATA: "v1/admin/certificate/data_sourcefunction/tcserial-data",
      STUDENTS_PROFILE_DATAS:
        "v1/admin/certificate/data_sourcefunction/students-profile-datas",
    },
    NOCS: {
      LIST_NOC_TYPE: "v1/admin/certificate/nocs/list",
      ADD_NOC_TYPE: "v1/admin/certificate/nocs/save",
      EDIT_NOC_TYPE: "v1/admin/certificate/nocs/update",
      REMOVE_NOC_TYPE: "v1/admin/certificate/nocs/delete",
    },
  },
  ADMISSION_ADMIN: {
    SC_ST_FREEE_APP_VERIFICATION: "v1/admin/ug/admission/free-app-verification",
    SC_ST_FREEE_PG_APP_VERIFICATION:
      "v1/admin/pg/admission/free-app-verification",
    PG_HOD_VERIFICATION: "v1/admin/pg/admission/hod-verification",
    UG_APPLICATION_DASHBOARD_COUNT: "v1/admin/ug/applications/dashboard-count",
    PG_APPLICATION_DASHBOARD_COUNT: "v1/admin/pg/applications/dashboard-count",
    UG_APPLICATION_REPORT: "v1/admin/ug/applications/dashboard-report",

    UG_APPLICATION_PREVIEW: "v1/admin/ug/admin/application",

    UG_UPDATE_CANDIDATE_PERSONAL: "v1/admin/ug/applications/update-personal",
    UG_UPDATE_CANDIDATE_MARKS: "v1/admin/ug/applications/update-marks",
    UG_UPDATE_APPLICATION_REJECT: "v1/admin/ug/applications/reject-application",
    UG_UPDATE_ALLOW_STEP: "v1/admin/ug/applications/allow-step",
    UG_APPLICATION_REVOKE_REJECT: "v1/admin/ug/applications/revoke-reject",
    UG_CHANGE_PROGRAMME: "v1/admin/ug/applications/change-programme",

    PG_APPLICATION_PREVIEW: "v1/admin/pg/admin/application",

    PG_UPDATE_CANDIDATE_PERSONAL: "v1/admin/pg/applications/update-personal",
    PG_UPDATE_CANDIDATE_MARKS: "v1/admin/pg/applications/update-marks",
    PG_UPDATE_APPLICATION_REJECT: "v1/admin/pg/applications/reject-application",
    PG_UPDATE_ALLOW_STEP: "v1/admin/pg/applications/allow-step",
    PG_APPLICATION_REVOKE_REJECT: "v1/admin/pg/applications/revoke-reject",
    PG_CHANGE_PROGRAMME: "v1/admin/pg/applications/change-programme",

    UG_COURSE_DETAIL: "v1/admin/ug/applications/course-details",
    PG_COURSE_DETAIL: "v1/admin/pg/applications/course-details",
  },
  IFORM: {
    POST_STUDENT_GRIEVANCE: "v1/oa/iform/student-grievance",
    POST_EVENT_REGISTRATION: "v1/oa/iform/event-registration",
    POST_CONTACT_FORM: "v1/oa/iform/contact-form",
    LIST_CONTACT_FORM: "v1/oa/iform/list-contact-form",
    LIST_EVENT_REGISTRATION: "v1/oa/iform/list-event-registration",
    UPDATE_ENQUIRY: "v1/oa/iform/update-enquiry",
    DELETE_ENQUIRY: "v1/oa/iform/delete-enquiry",
    MEMBERCATEGORY: "v1/oa/iform/list-member-category",
    MEMBERSUBCATEGORY: "v1/oa/iform/list-member-subcategory",
    SAVE_MEMBER_REGISTRATION: "v1/oa/iform/save-member-registration",
    LIST_STUDENT_TEMPLATE: "v1/oa/iform/list-all-template",
  },
  COPO: {
    LIST_ATTAINMENTS_TYPES: "v1/admin/copoattainments/list",
    ADD_ATTAINMENTS_TYPES: "v1/admin/copoattainments/save",
    REMOVE_ATTAINMENTS_TYPES: "v1/admin/copoattainments/delete",
    EDIT_ATTAINMENTS_TYPES: "v1/admin/copoattainments/update",
    LIST_GLOBAL_CONSOLIDATION: "v1/admin/copoattainments/list-global",
    TOTAL_ATTAINMENT_CLASS_LIST:
      "v1/admin/copoattainments/total-attainment-class-list",
    ATTAINMENT_CLASS_LIST: "v1/admin/copoattainments/attainment-class-list",
    ATTAINMENT_SUBJECT_FEEDBACK_ANSWERS:
      "v1/admin/copoattainments/subject-feedback-answers",
  },
  RECEIPT_AND_PAYMENTS: {
    SAVE_PARTY: "v1/admin/recpay/party/save",
    LIST_PARTIES: "v1/admin/recpay/party/list",
    DELETE_PARTY: "v1/admin/recpay/party/delete",
    UPDATE_PARTY: "v1/admin/recpay/party/update",

    SAVE_PAYMENT: "v1/admin/recpay/pay/save",
    LIST_PAYMENTS: "v1/admin/recpay/pay/list",
    DELETE_PAYMENT: "v1/admin/recpay/pay/delete",
    UPDATE_PAYMENT: "v1/admin/recpay/pay/update",
  },
  STORE: {
    GET_BILL_ID: "v1/admin/store/get-bill-no",
    GET_VOUCHER_ID: "v1/admin/store/get-voucher-id",
    GET_VOUCHER_ID_NO: "v1/admin/store/get-voucher-no",

    GET_VOUCHER_RECEPIT_ID_NO: "v1/admin/store/get-voucher-recepit-no",
    GET_VOUCHER_RECEPIT_ID: "v1/admin/store/get-voucher-recepit-id",

    LIST_ACCOUNT: "v1/admin/store/list-account",

    GET_BILL_RETURN_ID: "v1/admin/store/get-bill-return-no",
    GET_PURCHASE_BILL_ID: "v1/admin/store/get-purchase-bill-no",
    GET_PURCHASE_RETURN_BILL_ID: "v1/admin/store/get-purchase-return-bill-no",
    GET_STUDENT_DETAILS: "v1/admin/store/student-sales",
    GET_PRODUCTS: "v1/admin/store/list-product",
    SAVE_PRODUCTS: "v1/admin/store/save-product",
    SAVE_PRODUCTST: "v1/admin/store/save-productst",
    LIST_SALE_REPORTS: "v1/admin/store/sales-list",
    REMOVE_SALE_REPORTS: "v1/admin/store/remove-sale-list",

    UPDATE_PRODUCTS: "v1/admin/store/update-product",
    REMOVE_PRODUCTS: "v1/admin/store/remove-product",
    SAVE_SALES: "v1/admin/store/save-sales",
    SAVE_SALES_RETURN: "v1/admin/store/save-sales-return",
    LIST_PURCHASE_REPORTS: "v1/admin/store/purchase-list",
    SAVE_PURCHASE: "v1/admin/store/save-purchase",
    SAVE_PURCHASE_RETURN: "v1/admin/store/save-purchase-return",

    GET_INVOICE: "v1/admin/store/get-invoice",
    GET_PURCHAASE_INVOICE: "v1/admin/store/get-purchase-invoice",
    GET_CATEGORY: "v1/admin/store/list-category",
    SAVE_CATEGORY: "v1/admin/store/save-category",
    UPDATE_CATEGORY: "v1/admin/store/update-category",
    REMOVE_CATEGORY: "v1/admin/store/remove-category",
    LIST_PRODUCT_REPORTS: "v1/admin/store/product-list",
    LIST_ITEM_LIST: "v1/admin/store/item-list",

    LIST_PURCHASE_RETURN_LIST: "v1/admin/store/purchase-return-list",
    GET_PURCHAASE_RETURN_INVOICE: "v1/admin/store/get-purchase-return-invoice",
    GET_STUDENT_WISE_REPORT: "v1/admin/store/get-student-wise-report-list",
    STOCKLIST_REPORT: "v1/admin/store/stock-list",
    LIST_CATEGORY: "v1/admin/store/list-category",

    SAVE_ACCOUNT_GROUP: "v1/admin/store/save-accountgroup",
    UPDATE_ACCOUNT_GROUP: "v1/admin/store/update-accountgroup",
    LIST_ACCOUNT_GROUP: "v1/admin/store/list-accountgroup",
    DELETE_ACCOUNT_GROUP: "v1/admin/store/delete-accountgroup",

    LIST_ACCOUNTHEAD: "v1/admin/store/list-accounthead",
    SAVE_ACCOUNT_HEAD: "v1/admin/store/save-accounthead",
    SAVE_ACCOUNT_BANKACCOUNT: "v1/admin/store/save-accountbankhead",
    UPDATE_ACCOUNT_HEAD: "v1/admin/store/update-accounthead",
    DELETE_ACCOUNT_HEAD: "v1/admin/store/delete-accounthead",

    LIST_ACCOUNTHEAD_PAYMENT: "v1/admin/store/list-modepaymenthead",
    SAVE_PAYMENT: "v1/admin/store/save-payment",

    LIST_PAYMENT_MODE: "v1/admin/store/list-paymentmode",
    LIST_PAYMENT_LIST: "v1/admin/store/list-payment",
    LIST_RECEPIT_LIST: "v1/admin/store/list-recepit",
    LIST_JOURNAL_LIST: "v1/admin/store/list-journal",

    DELETE_RECEPIT_LIST: "v1/admin/store/delete-recepit",
    DELETE_PAYMENT: "v1/admin/store/delete-payment",
    DELETE_JOURNAL: "v1/admin/store/delete-journal",

    SAVE_RECEPIT: "v1/admin/store/save-recepit",
    SAVE_JOURNAL: "v1/admin/store/save-journal",

    GET_JOURNAL_ID_NO: "v1/admin/store/get-voucher-journal-no",
    GET_JOURNAL_ID: "v1/admin/store/get-voucher-journal-id",

    LIST_TRAIL_BALANCE: "v1/admin/store/list-trail-balance",
  },
};
